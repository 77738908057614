.feedback {
	&__container {
		transition: max-height 300ms ease-in-out;
	}

	&__additional-note {
		min-width: 30vw;
		max-width: 600px;
		max-height: 200vh;
		transition: max-height 300ms ease-in-out;
		animation: expand 600ms;

		@keyframes expand {
			0% {
				max-height: 150px;
			}

			100% {
				max-height: 200vh;
			}
		}

		&--closed {
			max-height: 0;
			overflow: hidden;
		}
	}
}
