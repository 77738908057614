@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

$part-column-template: minmax(7em, 3fr) minmax(0, 10fr) minmax(60px, 1.7fr) 20px minmax(300px, 10fr);

.vehicle-part {
	display: grid;
	grid-template-columns: 1em $part-column-template;
	gap: 3em;
	grid-template-areas: 'position partNumber information price quantity shoppingDetails';

	&[data-show-position='false'] {
		grid-template-areas: 'partNumber information price quantity shoppingDetails';
		grid-template-columns: $part-column-template;
	}

	&__shopping-details {
		display: grid;
		grid-area: shoppingDetails;
		grid-template-rows: 0.5fr 0.1fr;
		grid-template-columns: minmax(140px, 1.2fr) 0.3fr 0.8fr;

		.part-quantity-selection {
			align-items: start;
			grid-row: 1 / span 1;
			grid-column: 1 / span 1;
		}

		.part-availability {
			align-items: start;
			grid-row: 1 / span 1;
			grid-column: 2 / span 1;
		}

		.part-add-to-basket {
			justify-content: end;
			align-items: start;
			grid-row: 1 / span 1;
			grid-column: 3 / span 1;
		}

		.part-faster-availability {
			justify-content: end;
			align-items: start;
			grid-row: 2 / span 1;
			grid-column: 1 / span 3;
		}
	}

	&__item {
		&__position {
			grid-area: position;
		}

		&__part-number {
			grid-area: partNumber;
		}

		&__information {
			grid-area: information;

			&__title {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: flex-start;
			}
		}

		&__price {
			grid-area: price;

			&__item {
				grid-area: price;
			}
		}

		&__quantity {
			grid-area: quantity;
		}

		&__shop-details {
			grid-area: shoppingDetails;
		}
	}

	&--error {
		grid-row: 2 / span 1;
		grid-column: 1 / span 7;
	}

	&--grayed-out {
		background-color: $white-smoke;
	}
}
