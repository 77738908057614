@import '~base/scss/vars';

.faq-question {
	&__accordion {
		> .accordion-item {
			&--open {
				background-color: $white;
			}
		}

		.cms-content {
			ul {
				li {
					section {
						display: block;
					}
				}
			}
		}
	}
}
