@import '~base/scss/_breakpoints.scss';

.content-container {
	padding: 20px;

	&[data-reduce-margin='true'] {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	@include lt-sm {
		padding: 20px;
	}
}
