@import '~base/scss/_breakpoints.scss';
@import '~base/scss/vars';

@mixin button-radius($amount) {
	.button {
		border-radius: #{$amount} + 'px';
	}
}

.banner {
	display: grid;
	position: relative;

	&__item {
		display: grid;
		grid-template: 'media slide-content';
		grid-template-columns: minmax(0, max-content) minmax(min-content, auto);
		gap: 40px;
		height: 140px;
		overflow: clip;
		background-color: $prussian-blue;

		&--theme {
			&-dark {
				background-color: $prussian-blue;
				color: $white;

				@include button-radius(2);

				.banner__item__content {
					flex-flow: row;

					@include lt-md {
						flex-flow: column;
						align-items: flex-start;
					}

					@include lt-xxs {
						margin-top: 20px;
						align-items: flex-start;
					}
				}

				.banner__item__description {
					@include lt-md {
						font-size: 12px;
					}

					@include lt-xxs {
						margin: 10px 0 20px;
					}
				}

				.banner__item__button {
					margin: 0 20px;

					@include lt-lg {
						margin-left: 20px;
					}

					@include lt-md {
						margin-left: 0;
						margin-bottom: 10px;
					}

					@include lt-xxs {
						margin: auto;
					}
				}

				&-text {
					color: $white;
					background-repeat: no-repeat;
					background-image: url('@Static/images/campaign-text-only-theme.svg'), linear-gradient(90deg, $prussian-blue 0%, $bahama-blue 100%);
					background-position: 100%;

					@include button-radius(2);

					@include lt-xxs {
						background-image: url('@Static/images/campaign-text-only-theme-mobile.svg'), linear-gradient(90deg, $prussian-blue 0%, $bahama-blue 100%);
						background-size: cover;
					}
				}

				&-icon {
					background-image: linear-gradient(90deg, $prussian-blue 0%, $bahama-blue 100%);
					color: $white;
					position: relative;

					&__image {
						opacity: 0.05;
						width: 420px;
						height: 420px;
						position: absolute;
						top: -160px;
						right: 0;
						z-index: 1;

						@include lt-xxs {
							top: 130px;
							right: -50px;
						}
					}

					@include button-radius(2);
				}
			}

			&-light {
				background-color: #ececec;

				@include button-radius(4);
			}

			&-icons-text {
				background: linear-gradient(to right, $prussian-blue 30px, rgba($prussian-blue, 0)) no-repeat, url('@Static/images/icon-grid-faded.svg') rgba($prussian-blue, 1);
				background-size: 40%;
				color: $white;

				@include lt-xs {
					background-size: 400px;
				}

				@include button-radius(2);
			}
		}

		@include lt-lg {
			gap: 20px;
		}

		@include lt-md {
			grid-template-columns: minmax(min-content, auto) minmax(0, max-content);
			grid-template-areas: 'slide-content media';
		}

		@include lt-xxs {
			height: 100%;
			width: 100%;
			gap: 0;
			margin-bottom: 50px;

			&[data-no-media='false'] {
				display: flex;
				flex-direction: column;
			}
		}

		&__content {
			grid-area: slide-content;
			display: flex;
			align-items: center;
			z-index: 2;

			&[data-no-media='true'] {
				margin-left: 50px;
			}

			@include lt-md {
				margin-left: 50px;
				gap: 5px;
				flex-flow: column;
				align-items: baseline;
				justify-content: center;

				&[data-no-media='true'] {
					flex-flow: row;
					align-items: center;
					justify-content: start;
				}
			}

			@include lt-xxs {
				margin: 0 20px 10px;
				flex-flow: column;
				align-items: flex-start;

				&[data-no-media='true'] {
					flex-flow: column;
					margin-left: 20px;
				}
			}
		}

		&__text-content {
			display: flex;
			align-items: center;
			max-width: 700px;

			@include lt-lg {
				max-width: 550px;
				line-height: normal;
			}

			@include lt-xxs {
				flex-flow: column;
				align-items: flex-start;
			}
		}

		&__image-container {
			&[data-scale-image='false'] {
				height: 140px;
				width: 100%;
				overflow: hidden;
			}
		}

		img {
			&[data-scale-image='true'] {
				height: 140px;
				width: auto;
			}

			&[data-scale-image='false'] {
				object-fit: cover;
				width: 400px;
				height: 140px;
			}

			grid-area: media;

			@include lt-md {
				&[data-scale-image='false'] {
					width: 320px;
				}

				justify-self: self-end;
			}

			@include lt-xxs {
				&[data-scale-image='false'] {
					height: auto;
					width: 100%;
				}

				align-self: center;
			}
		}

		&__icon {
			background-image: linear-gradient(195deg, #40daf7 9%, #1f90c3 93%);
			border-radius: 10px;
			box-shadow: 0 1px 10px 0 rgba(0, 79, 115, 0.2);
			height: 98px;
			width: 98px;
			margin: 20px 20px 20px 10px;
			z-index: 2;

			@include lt-md {
				margin: 20px 20px 20px 0;
			}

			@include lt-xxs {
				height: 75px;
				width: 75px;
				margin: 20px 0;
			}
		}

		&__button {
			padding: 12px 15px;
			margin-left: 40px;
			height: max-content;

			@include lt-lg {
				margin-left: 20px;
			}

			@include lt-md {
				margin-left: 20px;
				justify-self: self-start;
			}

			@include lt-xxs {
				width: 100%;
				margin: 0;
			}
		}

		.button--tertiary-black:hover {
			background-color: #00adef;
			color: $white;

			@include button-radius(2);
		}

		.button--flat:hover {
			background-color: $black-two;
			color: $white-smoke;

			@include button-radius(2);
		}
	}

	&__custom-iteration {
		position: absolute;
		bottom: 0;
		justify-self: center;
		display: none;

		@include lt-xxs {
			display: flex;
		}
	}

	.slick-arrow {
		z-index: 2;
		bottom: -20px;

		svg {
			filter: drop-shadow(0 0 3px rgb(0 0 0 / 0.5));
		}

		@include lt-xxs {
			display: none !important;
		}
	}

	.slick-prev {
		justify-content: center;
		display: flex;
		align-items: center;
		left: 15px;
	}

	.slick-next {
		justify-content: center;
		display: flex;
		align-items: center;
		right: 15px;
	}

	.slick-prev::before,
	.slick-next::before {
		content: '';
	}

	.slick-slider {
		display: grid;
	}

	.slick-track {
		@include lt-xxs {
			display: flex;
		}

		.slick-slide {
			@include lt-xxs {
				display: flex;
				height: auto;
				align-items: stretch;
				justify-content: center;
			}
		}
	}

	.slick-dots {
		bottom: -5px;

		@include lt-xxs {
			display: none !important;
		}
	}

	.slick-dots li {
		width: 10px;
	}

	li.slick-active {
		div {
			span {
				background-color: $azure;
			}
		}
	}
}

.pager__item {
	height: 20px;
	position: relative;
	width: 20px;

	span {
		display: block;
		height: 5px;
		width: 5px;
		background: $silver;
		position: absolute;
		top: calc(50% - 1px);
		left: 10px;
		border-radius: 50%;
	}

	&--active {
		height: 20px;
		position: relative;
		width: 20px;

		span {
			display: block;
			height: 5px;
			width: 5px;
			position: absolute;
			top: calc(50% - 1px);
			left: 10px;
			border-radius: 50%;
			background-color: $azure;
		}
	}
}
