@import '~base/scss/_vars.scss';

$zoom-width-diff: 20px;

.explosion-drawing-gallery {
	width: 100%;
	margin: 0 auto;
	padding-top: 20px;

	.tooltip-wrapper {
		&[data-error='true'] {
			[id*='wepbarts-drawing-'] {
				margin-top: -4em;
			}
		}
	}

	&__tooltip {
		padding-right: 10px !important;
	}

	&__tooltip-container {
		flex-direction: column;
		max-height: 320px;
		max-width: 380px;
		padding: 10px 0;
	}

	&__tooltip-container-item {
		flex-direction: column;
		max-height: 200px;
		max-width: 380px;
		margin-right: 10px;
	}

	&__tooltip-upper {
		display: grid;
		grid-template-columns: 3fr 1fr;
	}

	&__tooltip-text-container {
		display: flex;
		flex-direction: column;
		max-height: 150px;

		&__tooltip-text {
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			max-width: 260px;
		}
	}

	&__callout {
		position: absolute;
		display: inline-block;
		height: 1.2vw;
		width: 2.25vw;

		&--active {
			@extend .explosion-drawing-gallery__callout;

			border-style: solid;
			border-width: 1px;
			border-color: #97e4ff;
		}

		&--disabled {
			@extend .explosion-drawing-gallery__callout;

			background: rgba(255, 255, 255, 0.6);
			pointer-events: none;
		}
	}

	&--selected {
		padding-bottom: $zoom-width-diff;
		border: 1px solid $very-light-pink-3;
		display: table;

		img {
			pointer-events: none;
		}

		&__arrow-button {
			vertical-align: middle;
			display: table-cell;
			height: 80px;
			overflow: hidden;
			padding-bottom: -50%;
		}
	}

	&__selections {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
	}

	&__zoom-pan-pinch {
		width: 100% !important;
	}

	&__error-curtain {
		background-color: rgba(0, 0, 0, 0.6);
	}

	.print-parts {
		border: 1px solid $greyish-brown-3;
		min-width: 40px;
		z-index: 9;

		&__menu {
			bottom: 50px;
			left: 5px;
			width: 200px;
			z-index: 10;

			&__option {
				&-text {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					&:hover {
						color: $azure;
					}
				}
			}
		}
	}
}
