@import '~base/scss/_breakpoints.scss';

.registration-modal {
	padding: 25px;

	.modal__plain {
		height: 100%;
	}

	@include lt-xs {
		padding: 15px;
	}

	.cancel {
		&__field {
			padding: 22px 30px;
		}

		@include lt-md {
			&__field {
				padding: 10px 15px;
			}
		}

		&__button {
			min-height: 35px;
		}
	}
}
