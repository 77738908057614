@import '~base/scss/_vars.scss';

.more-info-table {
	border-collapse: collapse;
	width: 100%;

	td {
		border: 1px solid $very-light-pink-3;
	}
}
