@import '~base/scss/_breakpoints.scss';
@import '~base/scss/_vars.scss';

.vehicle-part-drawing {
	display: grid;
	grid-template-rows: min-content auto;
	grid-template-areas:
		'gallery gallery gallery'
		'part-list part-list part-list';

	&__gallery {
		grid-area: gallery;
	}

	&__part-list {
		grid-area: part-list;
	}
}

.mq-4-behavior {
	@include sm {
		grid-row-start: 1;
		grid-column-start: 1;
		background-color: $white;
		z-index: 1;
		padding-left: 65px;
	}
}
