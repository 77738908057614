@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

.satisfaction-icon-button {
	top: 93.5%;
	right: 2%;
	bottom: 2%;
	border-radius: 4px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.23);
	background-color: $white;
	min-width: 50px;
	min-height: 50px;

	&[data-display='false'] {
		display: none;
	}

	&__face-button {
		animation: none;

		&:hover {
			color: $cerulean;
			animation: none;
		}
	}
}

.bouncing {
	position: relative;
	animation-name: bounce-animation;
	animation-duration: 1.2s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 3;
}

@keyframes bounce-animation {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}

	10% {
		transform: translateY(-5px);
	}

	30%,
	60% {
		transform: translateY(-3px);
	}

	40%,
	70% {
		transform: translateY(-1px);
	}
}
