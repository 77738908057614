.form {
	&__upload {
		&__wrapper {
			margin-top: 25px;
		}

		&__file-name {
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&__form-field {
			padding-left: 0 !important;
		}
	}
}
