.products {
	align-content: flex-start;
	min-height: 500px;

	&-product-item {
		&.grid__cell:nth-child(n) {
			padding-left: 10px;
		}

		&.grid__cell {
			padding-right: 10px;
			padding-left: 10px;
			height: fit-content;
		}
	}
}
