@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

$space-between-sections: 80px;
$space-between-boxes: 40px;
$space-between-cards: 25px;

@mixin image-cover {
	aspect-ratio: 16 / 9;
	object-fit: cover;
}

.part-card {
	overflow-wrap: anywhere;
	gap: $space-between-cards;
	grid-auto-flow: row;
	grid-template-columns: auto;
	grid-template-rows: max-content auto;

	&__img {
		object-fit: cover;
	}

	&__description {
		grid-auto-flow: row;
		grid-template-columns: auto;
		grid-template-rows: auto 1fr auto;
	}

	&__button {
		justify-self: flex-start;

		&--stretched {
			justify-self: stretch;
		}
	}
}
