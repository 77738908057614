@import '~base/scss/_breakpoints.scss';
@import '~base/scss/_vars.scss';

.catalogue-parts {
	&__container {
		@include sm {
			display: grid;
			grid-template-columns: 1fr;
		}
	}

	&__header {
		padding-right: 10px;
		padding-left: 10px;
		flex-wrap: wrap-reverse;

		&[data-flex-column-reverse='true'] {
			flex-direction: column-reverse;
		}

		@include lt-sm {
			flex-direction: row !important;

			.small-bar {
				min-width: 240px;
				width: 100%;
			}
		}
	}
}
