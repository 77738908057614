@import '~base/scss/vars';

.working-on-vehicle {
	&__search {
		max-width: 0;
		margin: 0;
		transition: max-width 300ms ease-in-out;

		&__subtext {
			max-height: 100vh;
			transition: max-height 300ms ease-in-out;

			&[data-hidden='true'] {
				max-height: 0;
				overflow: hidden;
			}
		}

		&[data-search-init='true'] {
			max-width: 100vh;
			margin: unset;
		}
	}
}

.linas-search {
	grid-auto-flow: column;
	grid-template-columns: minmax(0, 1fr) 100px;
	height: 60px;
	transition: all 300ms ease-in-out;
	z-index: 1;

	*[data-clear='true'] {
		grid-template-columns: minmax(0, 1fr) 100px 100px;
	}

	&[data-search-init='true'] {
		max-width: 100vw;
		margin-right: 50px;
	}

	&__input {
		height: 50px !important;
		border: none !important;
		font-size: 16px !important;
		min-width: 70px;

		&:focus-visible {
			outline: none;
		}
	}

	&__queries {
		overflow-x: auto;
		white-space: nowrap;
		flex-direction: row;

		&::-webkit-scrollbar {
			display: none;
		}

		&__offset {
			min-width: 40px;
		}
	}

	&__query {
		white-space: nowrap;
		border-radius: 4px;
		font-family: DaimlerCS-Bold;

		&__icon {
			&:focus-visible {
				color: $azure;
			}
		}
	}

	&__suggestions {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__mask {
		&--left {
			left: 0;
			background: linear-gradient(to right, white, rgba(255, 255, 255, 0) 50%);
			transition: opacity 100ms ease-in-out;
			pointer-events: none;
		}

		&--right {
			right: 0;
			background: linear-gradient(to left, white, rgba(255, 255, 255, 0) 50%);
			transition: opacity 100ms ease-in-out;
			pointer-events: none;
		}
	}

	&__card {
		z-index: 2;
		top: 65px;
	}
}

.result-list {
	animation: expand 300ms;
	overflow: hidden;

	@keyframes expand {
		0% {
			transform: translateY(30%);
		}

		100% {
			transform: translateY(0%);
		}
	}

	&__result {
		min-height: 115px;

		&:hover {
			border-radius: 4px;
			background-color: $white-smoke;
		}

		&__unlock-vin {
			gap: 5px;
			transition: none;
		}
	}

	&--not-found,
	&--error,
	&--loading {
		height: 400px;
	}

	&--not-found {
		padding-top: 100px;
	}

	&__filter-buttons {
		&--active {
			&:hover {
				cursor: default;
				background-color: $black-two !important;
				color: $white !important;
			}
		}
	}
}
