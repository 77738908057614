@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

.part-catalogue {
	min-height: 625px;
	width: inherit;

	&__header {
		padding: 20px 0 20px 0;

		@include lt-sm {
			width: 100%;
		}

		&__right-content {
			@include lt-sm {
				width: 100%;
			}
		}

		&__search {
			@include lt-sm {
				flex-grow: 1;
			}
		}
	}

	&__lists {
		height: inherit;
		max-width: 100%;
		overflow-x: auto;

		&[data-vin='true'] {
			& > * {
				margin: 5px;
			}
		}

		&-item {
			min-width: 300px;

			[data-vin='true'] & {
				max-width: 540px;
				flex: 1;
			}
		}
	}

	&__tiles {
		height: 625px;
		max-width: 100%;
		overflow-x: auto;

		&-item {
			grid-template-columns: repeat(auto-fill, minmax(240px, 1.5fr));
			grid-gap: 20px;
		}

		::-webkit-scrollbar {
			display: none;
		}
	}

	&__part-search {
		z-index: 9999;
	}

	&__change-catalog {
		white-space: nowrap;
	}

	&__filter_options {
		&[data-notification='true'] {
			span {
				position: relative;

				&::before {
					position: absolute;
					border: 5px solid $white;
					border-radius: 6px;
					right: 1px;
					top: 1px;
					content: '';
				}

				&::after {
					position: absolute;
					border: 4px solid $blood-red;
					border-radius: 4px;
					right: 2px;
					top: 2px;
					content: '';
				}
			}
		}
	}

	.catalogue-filter {
		&__tile {
			&:first-child {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}

			&:nth-child(2) {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}

		&__list {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}

	.scroll-gradient {
		pointer-events: none;

		.scroll-sign {
			background-color: $dark-gray;
			border-radius: 50%;
		}

		&--up {
			top: 0;
			z-index: 1;
			background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8));
		}

		&--down {
			bottom: 0;
			background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8));
		}
	}
}
