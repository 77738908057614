@import '~base/scss/_vars.scss';
$grid-spacing-xlarge: calc(#{$grid-spacing-large} * 2);

.rmi-license {
	.textButton--disabled {
		opacity: 1;
	}

	display: grid;
	grid-gap: $grid-spacing-small;
	grid-auto-flow: column;
	align-items: center;
	grid-template-columns: 1fr 1.2fr;

	&__message {
		display: flex;
		justify-content: flex-end;
		grid-column: 3 / span 2;
	}

	&__vehicle-types {
		.vehicle-types__item {
			display: grid;

			span {
				height: auto;
				place-self: center;
			}
		}
	}
}

.versioned-license {
	section.train__center {
		width: 100%;
	}

	&__item {
		width: 100%;
		display: grid;
		grid-gap: $grid-spacing-small;
		grid-auto-flow: column;
		align-items: center;
		grid-template-columns: minmax(150px, 3fr) 2.5fr 0.5fr 1.5fr;

		&__info {
			grid-column: 2 / span 1;
		}
	}
}
