@import '~base/scss/_vars.scss';

.table {
	table-layout: fixed;

	tbody {
		width: 100%;
	}

	tr {
		width: 100%;
	}

	tr:nth-child(2n-1) {
		background-color: $white-smoke;
	}

	td {
		padding: 5px 10px;
		color: $black-two;
	}

	tr td:first-child {
		font-weight: normal;
		font-size: 16px;
		width: 250px;
		color: $black-two;
	}

	tr td:last-child {
		font-weight: normal;
		font-size: 16px;
		color: $brownish-grey;
		word-break: break-word;

		&:hover:not([data-value='-']):not([data-value='']) {
			color: $azure !important;
			cursor: pointer;

			.copy-clipboard {
				visibility: visible;
			}
		}
	}

	.copy-clipboard {
		visibility: hidden;
		vertical-align: text-bottom;

		&__tooltip {
			position: absolute !important;
			z-index: 9999;
		}
	}

	.aggregate-number {
		font-weight: normal;
		font-size: 16px;
		color: $black-two;
	}
}
