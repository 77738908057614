@import '~base/scss/_vars.scss';

$image-height: 120px;

.wrapper {
	display: grid;
	grid-auto-flow: column;
	align-items: flex-end;

	&__icon {
		position: relative;
		bottom: ($image-height / 4) * 3;
	}
}

.group-slider {
	display: grid;
	gap: 20px;
	grid-auto-flow: column;
	width: 100%;
	overflow-x: auto;
	padding: 20px 0 10px;
	justify-content: flex-start;

	&-item {
		display: grid;

		&__title {
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&__image-list {
			width: 100%;
			display: grid;
			grid-auto-flow: column;
			gap: 10px;
		}

		&__image {
			height: $image-height;

			&:hover {
				cursor: pointer;
				outline: solid 2px $azure;
				outline-offset: -2px;
			}

			&--selected {
				outline: solid 2px $azure;
				outline-offset: -2px;
			}
		}

		img {
			padding: 3px;
			max-width: 100%;
			max-height: 100%;
		}
	}
}
