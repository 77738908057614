@import '~base/scss/_breakpoints.scss';
@import '~base/scss/vars';

.genuine-parts__product-details {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 1fr 1fr;

	&__image-slider {
		.slick-dots li {
			width: 10px;
		}

		.slick-arrow {
			margin-right: 20px;
		}

		.slick-prev::before,
		.slick-next::before {
			content: '';
		}

		.slick-slider {
			display: grid;
		}

		.pager__item {
			height: 20px;
			position: relative;

			span {
				display: block;
				height: 10px;
				width: 10px;
				background: $silver;
				position: absolute;
				top: calc(50% - 1px);
				left: 0;
				border-radius: 50%;
			}
		}

		li.slick-active {
			div {
				span {
					background-color: $azure;
				}
			}
		}

		.slick-track {
			position: relative;
			display: flex;
			align-items: center;
		}
	}

	&__image-view-360 {
		display: flex;
		justify-content: flex-end;
	}

	&__button {
		width: fit-content;
		padding: 10px;
	}
}
