.credits-table {
	width: 100%;
	display: grid;
	grid-gap: 10px;
	grid-auto-flow: column;
	align-items: center;

	&__header {
		grid-template-columns: 5fr 6fr;
	}

	&__packages {
		grid-template-columns: 1fr 10fr;
	}

	&__licenses {
		grid-template-columns: 1fr 1fr 1fr 7fr 1fr;
	}
}
