@import '~base/scss/vars';

.uc-consent-info {
	max-width: 900px;
	max-height: calc(var(--app-height) - 50px);

	.consent-toggle {
		width: 45px;
		height: 20px;
	}

	&-content {
		height: calc(var(--app-height) - 200px);
		overflow: scroll;

		&__service {
			white-space: break-spaces;

			&-disclosure {
				&-info {
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					grid-auto-flow: column;
				}
			}
		}

		ul {
			list-style-type: none;
		}
	}

	&-footer {
		padding: 20px 30px;
		border-top: 1px solid;
		border-color: $asphalt;

		button {
			padding: 10px;
			right: auto;
			border-radius: 6px;
			background-color: $celestial-blue;
			box-shadow: 0 1px 4px rgb(0 0 0 / 40%);
		}
	}
}
