@import '~base/scss/_vars.scss';

.footnote {
	&__legacy-text {
		white-space: pre-wrap;
	}

	&-container {
		max-height: 700px;
	}

	&__header {
		word-break: keep-all;
		line-height: normal;
	}

	&__table {
		border-collapse: collapse;
		width: 100%;

		td {
			&:first-child {
				width: 8%;
			}

			border: 1px solid $very-light-pink-3;
		}
	}
}
