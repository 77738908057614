@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

$header-height: 76px;
$logo-height: 45px;

.campaign-header {
	height: $header-height;
	background-color: $black;
	grid-template: 'logo language login';
	grid-template-columns: 1fr max-content max-content;

	@include xxs {
		padding: 0 5vw;
	}

	@include xxs {
		grid-template: 'language logo login';
		grid-auto-flow: column;
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	&__login {
		grid-area: login;
	}

	&__language {
		grid-area: language;
		justify-content: flex-end;

		@include xxs {
			justify-content: flex-start;
		}
	}

	&__logo {
		height: $logo-height;
		margin: 0 45px;
		grid-area: logo;

		@include xxs {
			justify-self: center;
			height: 37px;
			min-width: 50px;
		}

		&__img {
			background-image: url('@Static/images/logo-star-mercedes-benz.svg');
			background-size: contain;
			background-repeat: no-repeat;

			@include xxs {
				background-image: url('@Static/images/logo-compact.svg');
				background-position: center;
			}
		}
	}
}

.campaign-headline {
	background-size: cover !important;
	background-repeat: no-repeat !important;
	padding-bottom: 55px;
	min-height: 70vh;

	&__grid {
		grid-template: 1fr 2fr / 9fr 3fr;
		grid-template-areas:
			". logo"
			"title .";

		@include xxs {
			grid-template: 1fr / 1fr;
			grid-template-areas:
				"logo"
				"title";
		}

		&__logo {
			direction: rtl;
			grid-area: logo;

			@include xxs {
				direction: ltr;
			}
		}

		&__title {
			line-height: 55px;
			grid-area: title;
		}

		&__button {
			@include xxs {
				width: 100%;
			}
		}
	}
}
