@import '~base/scss/_vars.scss';

.incident-management-banner {
	position: relative;
	max-height: 500px;
	overflow: hidden;
	-webkit-transition: max-height 0.5s, opacity 0.1s;
	-moz-transition: max-height 0.5s, opacity 0.1s;
	-ms-transition: max-height 0.5s, opacity 0.1s;
	-o-transition: max-height 0.5s, opacity 0.1s;
	transition: max-height 0.5s, opacity 0.1s;

	&__wrapper {
		display: grid;
		grid-template-columns: 1fr 40px;
		padding: calc(var(--padding-base) * 6) calc(var(--padding-base) * 3);
	}

	&__close {
		align-self: flex-start;
	}

	&--close {
		-webkit-transition: max-height 0.3s, opacity 0.1s;
		-moz-transition: max-height 0.3s, opacity 0.1s;
		-ms-transition: max-height 0.3s, opacity 0.1s;
		-o-transition: max-height 0.3s, opacity 0.1s;
		transition: max-height 0.3s, opacity 0.1s;
		overflow: hidden;
		max-height: 0;
	}

	.description {
		&-read-more {
			text-decoration: underline;
		}

		span {
			&:hover {
				color: $azure;
				cursor: pointer;
			}
		}
	}
}
