@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

$box-shadow-value: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
$search-bar-width: 595px;
$search-bar-top-margin: -25px;

@mixin image-cover {
	aspect-ratio: 3 / 2;
	object-fit: contain;
}

@mixin truncate {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.vehicle-list {
	box-shadow: $box-shadow-value;

	&__search {
		padding-bottom: 0;
		z-index: 5;

		.input__card {
			top: calc(100% + 12px);
			z-index: 5;
		}
	}

	&__message {
		width: 100%;
		min-height: 250px;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	&--empty {
		flex-flow: column;
		box-shadow: $box-shadow-value;
		gap: 10px;
	}

	&__header {
		@include lt-md {
			height: auto;
		}

		&__add-vehicle {
			cursor: pointer;
			height: 100%;
			margin-left: auto;

			&:hover {
				color: $cerulean;

				.vehicle-list__header__add-icon {
					background-color: $cerulean;
				}
			}
		}

		&__filter {
			gap: 5px;
			padding: 15px 0;
			flex-wrap: wrap;
		}
	}

	&__load-more {
		box-shadow: $box-shadow-value;
		height: 130px;
	}

	.vehicle-tile {
		opacity: 1;
		gap: 20px;
		display: grid;
		grid-template-columns: 173px 16fr 29px 120px;
		border-left: 4px solid $white;
		margin-bottom: 1px;
		height: auto;
		min-height: 136px;
		transition: min-height 0.35s ease-out;

		&:not(:last-child) {
			border-bottom: 1px solid #d9d9d9;
		}

		&--hidden {
			opacity: 0;
			pointer-events: none;
			height: 0;
			min-height: 0 !important;
			max-height: 0;
			padding: 0;
			margin: 0;
		}

		@include lt-sm {
			height: auto;
			min-height: 136px;
			padding: 15px 10px;
			gap: 15px;
			grid-template-columns: 173px 16fr minmax(50px, 2fr) 120px;
		}

		@include lt-xs {
			gap: 10px;
			grid-template-columns: 150px 16fr minmax(30px, 2fr) 100px;
		}

		@include lt-xxs {
			grid-template-columns: auto;
		}

		&:hover {
			border-left: 4px solid $azure;
			background-color: $white-smoke;

			.vehicle-tile__delete {
				visibility: visible;
			}
		}

		&__image {
			max-width: 180px;
			transition: all 350ms ease-in-out;
			opacity: 0;
			transform: translateX(5px);

			&[data-loaded='true'] {
				opacity: 1;
				transform: translateX(0);
			}

			@include lt-sm {
				height: 130px;
				object-position: center;
			}

			@include image-cover;
		}

		&__information {
			display: grid;
			grid-template-columns: 1.5fr 2fr;
			grid-template-areas:
				'model status'
				'license status';

			@include lt-sm {
				grid-template-columns: 1.5fr 2fr;
				gap: 15px;
				grid-template-areas:
					'model license'
					'status status';
			}

			&[data-has-status='true'] {
				grid-template-areas:
					'model status'
					'license status';

				@include lt-sm {
					gap: 15px;
					grid-template-areas:
						'model license'
						'status status';
				}
			}
		}

		&__status-column {
			grid-area: status;
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include lt-sm {
				padding: 0;
				gap: 5px;
			}

			@include lt-xs {
				padding: 0;
				gap: 10px;
			}

			&__row {
				animation: fadeRight 350ms forwards;
			}

			@keyframes fadeRight {
				from {
					opacity: 0;
					transform: translateY(10px);
				}

				to {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}

		&__model-vin {
			grid-area: model;
		}

		&__info-box,
		&__license-plate {
			grid-area: license;

			@include lt-sm {
				padding: 0 30px;
			}

			@include lt-xs {
				padding: 0 5px;
			}

			@include truncate;

			& > :only-child {
				margin-top: revert;
			}

			&__line {
				@include truncate;
			}
		}

		&__delete {
			display: flex;
			justify-content: flex-end;
			visibility: hidden;
			width: 100%;

			svg {
				width: auto;
			}

			@include lt-sm {
				visibility: visible;
			}

			&:hover {
				color: $blood-red;
				cursor: pointer;
			}
		}
	}
}

.search-bar-vehicle {
	display: flex;
	width: 800px;
	max-width: 800px;
	height: 60px;

	&__placeholder {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	input {
		border: 0 !important;
	}

	&[data-active='true'] {
		z-index: 9999;
	}

	@include lt-sm {
		width: 100%;
	}
}

.parts {
	&__search-result {
		z-index: 4;
	}
}
