@import '~base/scss/vars';

.interaction-card {
	border: 1px solid $asphalt;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);

	&__footer {
		border-top: 1px solid $asphalt;
	}

	.disabled {
		pointer-events: none;
		opacity: 0.4;
	}
}
