@import '~base/scss/_vars.scss';

.vehicle-information {
	min-height: 420px;
	grid-template-columns: 400px 2fr;
	grid-auto-flow: column;
	overflow: hidden;
	transition: height 1s linear;

	&__img {
		max-width: 820px;
		top: 0;
		left: 0%;
		transform: translate(-33%, -23%);

		img {
			user-select: none;
		}
	}

	&__model-name {
		z-index: -1;
		font-size: 82px;
		color: $white-smoke;
		top: 0;
		transform: translateY(-40%);
		user-select: none;
		white-space: nowrap;
	}

	&__notes {
		align-self: flex-end;

		.text-area__placeholder {
			display: none;
		}

		textarea {
			height: unset !important;
			min-height: 80px;
			overflow-y: auto;
		}
	}

	&__details {
		font-weight: bold;
		z-index: 2;
	}

	&__delete-vehicle-button {
		cursor: pointer;

		&:hover {
			color: $red;

			.icon {
				color: $red;
			}
		}
	}

	&__general {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 30vw;
	}
}
