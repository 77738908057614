@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

.uc-consent-settings {
	max-width: 500px;
	border-radius: 1%;

	&-content {
		@include lt-xxs {
			display: grid;
			gap: 10px;
			margin-top: 10px;
		}
	}

	&-ui-toggle {
		width: 50px;
		height: 20px;
	}

	.uc-cookie-settings {
		overflow-y: auto;

		&-sub-header {
			width: 100%;

			span {
				color: $celestial-blue;
			}
		}

		&--basic {
			max-height: 260px;
		}

		&--advanced {
			margin: 20px 0;
			max-height: 200px;

			&-copy {
				span:hover {
					text-decoration: underline;
				}
			}

			.uc-cookie-id {
				width: 80px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				justify-self: flex-start;
			}
		}

		&-history {
			margin: 20px 0;
			max-height: 200px;
		}

		&-info-icon {
			span {
				color: $celestial-blue;
			}
		}

		.consent-toggle {
			width: 45px;
			height: 20px;

			&--advanced {
				label {
					border-radius: 2px;
				}
			}
		}

		small {
			color: $bluish-grey;
		}

		li {
			width: 100%;
		}
	}

	&-footer {
		bottom: 5px;
		left: 0;
		padding: 10px 20px;
		width: 100%;

		button {
			width: 100%;
			border-radius: 6px;
			background-color: $celestial-blue;
			box-shadow: 0 1px 4px rgb(0 0 0 / 40%);

			@include lt-xxs {
				border-radius: 0;
				box-shadow: none;
				padding: 15px 0;
			}
		}
	}
}
