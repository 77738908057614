@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

.new-features__modal {
	.modal__content {
		max-height: unset;
		padding-bottom: 0;
		flex: 1 1;
	}

	.modal__header {
		border-bottom: 1px solid #ccc;
	}

	.feature-dot {
		height: 8px;
		width: 8px;
		background-color: $black-two;
		border-radius: 50%;
		display: inline-block;
		opacity: 0.5;

		&--active {
			opacity: 1;
			background-color: $azure;
		}
	}

	.center-dots-horizontally {
		display: flex;
		justify-content: center;
	}

	.feature-container {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: 1fr 2fr;
		padding: $grid-spacing-medium;
		gap: calc(2 * #{$grid-spacing-medium});

		img {
			width: 100%;
			height: auto;
			object-fit: cover;
			padding: 20px;
		}
	}

	@include lt-sm {
		.button--secondary {
			display: none;
		}
	}
}
