@import '~base/scss/_vars.scss';

.shopping-basket {
	&__previous-button {
		gap: 5px;

		&:hover {
			color: $cerulean;
		}
	}

	&-container {
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	}

	&--loading,
	&--empty {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);
	}

	&__cart-summary {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__remove-button {
		&:hover {
			color: red;
		}
	}
}
