@mixin help-section-grid {
	gap: 5px;
	grid-template-columns: min-content minmax(0, 1fr);
	grid-template-areas: "logo title" ". description";
	grid-template-rows: min-content auto;

	&__logo {
		grid-area: logo;
		align-content: center;
	}

	&__title {
		grid-area: title;
		align-content: center;
	}

	&__description {
		grid-area: description;
	}
}

.section-wrapper {
	padding: 20px 40px 40px 20px;
	width: 100%;
	height: 100%;
}

.help-section {
	min-height: 220px;
	grid-auto-flow: column;
	grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
}

.retailer-contact {
	@include help-section-grid;

	&__button {
		max-width: 120px;
	}

	&__loading {
		align-content: center;
	}

	&__message {
		grid-auto-flow: column;
	}
}

.ask-for-help {
	@include help-section-grid;

	&__button {
		text-wrap: balance;
	}
}
