@import '~base/scss/_vars.scss';

.color-code-info {
	&-container {
		flex-wrap: nowrap;
		display: grid;
		max-height: 700px;
	}

	&__header {
		word-break: keep-all;
	}

	&__wrapper {
		border-bottom: 1px solid $asphalt;
	}

	&__error {
		text-align: center;
	}

	&__table {
		border-collapse: collapse;
		width: 100%;

		td {
			border: 1px solid $very-light-pink-3;
		}
	}
}
