.suggestion-product-box {
	grid-auto-flow: row;
	grid-template-columns: auto;
	grid-template-rows: max-content auto;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);

	img {
		justify-self: center;
		aspect-ratio: 383/236;
		object-fit: contain;
	}
}
