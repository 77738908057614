@import '~base/scss/_vars.scss';

body {
	overflow: hidden;
}

.accept-terms-and-data-protection__checkbox {
	p {
		margin: 0;
	}
}

.accept-terms-and-data-protection__content {
	min-height: 200px;
	max-height: 60vh;
}
