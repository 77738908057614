@import '~base/scss/_vars.scss';

@mixin aggregateWidthLonger() {
	&[data-aggregate-width-longer='true'] {
		@content;
	}
}

@mixin aggregateWidthNotLonger() {
	&[data-aggregate-width-longer='false'] {
		@content;
	}
}

.wrapper {
	align-items: center;
}

.wrapper-outer {
	align-items: center;
	overflow-y: hidden;

	@include aggregateWidthLonger() {
		overflow-x: visible;
	}

	@include aggregateWidthNotLonger() {
		overflow-x: unset;
	}

	&::-webkit-scrollbar {
		height: 0;
	}
}

.aggregate-item {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: inherit;
	overflow-x: unset !important;

	&__button-wrapper {
		height: 43px;
		padding-top: 2px;
	}

	&__button {
		padding-left: 10px;
		padding-right: 10px;
	}
}
