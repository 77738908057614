@import '~base/scss/_vars.scss';

$user-item-height: 70px;
$user-avatar-width: 48px;
$grid-spacing-medium: calc(#{$grid-spacing-small} * 2);
$grid-spacing-large: calc(#{$grid-spacing-small} * 4);
$icon-width: 54px;

@mixin grid-centered {
	display: grid;
	align-items: center;
}

.users {
	display: grid;
	column-gap: $grid-spacing-large;
	row-gap: $grid-spacing-large;

	& > :only-child {
		grid-column: 1/-1;
	}

	.user {
		@include grid-centered;

		&.hidden {
			max-height: 0;
			min-height: 0;
			overflow: hidden;
			opacity: 0;
		}

		&.animated {
			animation: expandUser 2s;

			@keyframes expandUser {
				0% {
					max-height: 0;
					min-height: 0;
					overflow: hidden;
					opacity: 0;
				}

				50% {
					max-height: 100px;
					min-height: 70px;
					overflow: unset;
					opacity: 0;
				}

				100% {
					opacity: 1;
				}
			}
		}

		&[data-error='true'] {
			align-items: flex-start;
			padding-top: 10px;
		}

		&[data-new-user='true'] {
			scroll-margin-top: 15px;
		}

		&:nth-child(odd) {
			background-color: $white-smoke;
		}

		grid-auto-flow: column;
		grid-gap: $grid-spacing-medium;
		grid-template-columns: minmax(230px, 1.5fr) 3fr 2.5fr;
		min-height: $user-item-height;

		&__list {
			column-gap: $grid-spacing-large;
			row-gap: $grid-spacing-small;
		}

		&__icon {
			width: $user-avatar-width;
			height: $user-avatar-width;
			border-radius: 50%;
			justify-content: center;
			align-items: center;
			letter-spacing: -1px;
		}

		&__contact {
			@include grid-centered;

			column-gap: $grid-spacing-medium;
			grid-template-columns: max-content 1fr;
		}

		&__no-user {
			justify-self: center;
			text-align: center;
		}

		&__add-user {
			cursor: pointer;

			&:hover {
				color: $cerulean;

				svg {
					color: $cerulean;
				}
			}
		}

		&__more-menu {
			display: grid;

			&[data-error='true'] {
				grid-template-rows: 1fr 1fr;
				row-gap: $grid-spacing-medium;
			}

			&__item {
				display: flex;
				justify-content: flex-end;

				&[data-error='true'] {
					align-items: center;
					min-width: 306px;
				}
			}
		}
	}
}
