@import '~base/scss/_vars.scss';
$teaser-breakpoint-width: 1150px;

.vehicle-part-teaser {
	.curtain {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		background-color: rgba(51, 51, 51, 0.8);
	}

	&--error {
		border: solid 1px $silver-chalice;
	}

	&__container {
		border: solid 1px $silver-chalice;
		display: grid;
		grid-template-rows: 0.5fr 0.2fr 1fr 1fr min-content;
		grid-template-columns: 1fr 0.5fr;
		grid-template-areas:
			'description net-price'
			'category local-price'
			'availability add-to-basket'
			'availability add-to-basket'
			'basket-error faster-available';

		@media only screen and (max-width: $teaser-breakpoint-width) {
			grid-template-rows: repeat(5, 1fr);
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas:
				'description net-price'
				'category local-price'
				'availability availability'
				'add-to-basket add-to-basket'
				'basket-error faster-available';
		}

		.part {
			&__description {
				grid-area: description;
				gap: $grid-spacing-small;
			}

			&__category {
				grid-area: category;
				gap: $grid-spacing-small;
			}

			&__net-price {
				grid-area: net-price;
				justify-content: flex-end;
			}

			&__local-price {
				grid-area: local-price;
				justify-content: flex-end;
			}

			&__availability {
				grid-area: availability;
				gap: $grid-spacing-small;
			}

			&__add-to-basket {
				grid-area: add-to-basket;
				gap: $grid-spacing-small;
				justify-content: flex-end;
			}

			&--error {
				grid-area: basket-error;
				justify-content: flex-start;
			}

			&__faster-available {
				grid-area: faster-available;
			}
		}
	}
}
