@import '~base/scss/_breakpoints.scss';
$space-in-middle-of-sections: 40px;
$space-between-category-items: 30px;

@mixin grid-row {
	@media only screen and (max-width: 650px) {
		grid-template-columns: auto;
		grid-column: auto;
		grid-auto-flow: row;
	}
}

@mixin flex-justify-center-wider {
	@media only screen and (min-width: 750px) {
		display: flex;
		justify-content: center;
		gap: 40px;
	}
}

.division-section {
	gap: $space-in-middle-of-sections;
	grid-template-columns: repeat(2, 1fr);

	@include grid-row;
}

.product-category {
	flex-direction: column;

	&__chips {
		flex-direction: row;
	}

	&__category-list {
		gap: $space-between-category-items;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		grid-column: 1 / 5;
	}
}

.comparison {
	&__bullet-list {
		display: block;

		@include flex-justify-center-wider;
	}

	&__wrapper {
		flex-wrap: wrap;
		gap: 30px;
	}

	&__box {
		min-width: 280px;
		max-width: 320px;
		flex-basis: 280px;
		flex-shrink: 1;
		flex-grow: 1;
		list-style-type: none;
		justify-content: space-around;

		@include xxs {
			flex-grow: 0;
		}

		img {
			max-width: 95px;
			max-height: 65px;
			object-fit: scale-down;
		}
	}
}

.advantages-section {
	grid-template-columns: 1fr 1fr;
	grid-auto-flow: column;
	gap: $space-in-middle-of-sections;

	@include lt-xs {
		grid-auto-flow: row;
		grid-template-columns: 1fr;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-self: center;
	}

	&__button {
		width: fit-content;
	}
}
