@import '~base/scss/_breakpoints.scss';
@import '~base/scss/_vars.scss';

.parts-order-detail-modal {
	height: 896px;

	&__content {
		.order-info {
			display: grid;
			grid-auto-flow: column;
			gap: 10px;

			@include lt-md {
				grid-template-columns: repeat(3, 1fr);
				grid-auto-flow: row;
				gap: 40px;
			}

			&__comment {
				overflow-wrap: break-word;
				overflow: auto;
				max-width: 100%;

				@include lt-md {
					grid-row: 2 / span 1;
				}
			}

			&__text-column {
				vertical-align: top;
			}

			&__summary {
				display: flex;
				flex: 1;
				flex-direction: column;
				align-items: flex-end;
				max-width: fit-content;
			}

			&__item-sum {
				display: grid;
				grid-template-columns: 1fr;
			}
		}

		.order-list {
			&__item {
				&-name {
					word-break: break-all;
				}

				&-price {
					min-width: 260px;

					@include lt-md {
						min-width: 160px;
					}
				}
			}
		}

		.price-summary {
			display: grid;
			grid-template-columns: 1fr 1fr minmax(min-content, 350px);
			width: 100%;
		}

		.order-export {
			&__menu {
				top: 0;
				right: 0;
				width: auto;

				&__option {
					&-text {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					&:hover {
						background-color: $white-smoke;
						color: $azure;
					}
				}
			}
		}
	}
}
