@import '~base/scss/_vars.scss';

.color-selection-item {
	display: grid;

	&__color-code {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&--single {
		grid-template-columns: 4vw 10vw 15vw 10vw 15vw 9vw 8vw;
	}

	&--multiple {
		grid-template-columns: 3.5vw 3.5vw 14vw 16vw 7vw 16vw 4vw 7vw;
	}

	&:nth-child(odd) {
		background-color: $white-smoke;
		border-radius: 4px;
	}
}
