@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

.platform-search-content {
	min-height: 300px;
}

.platform-search-wrapper {
	grid-auto-flow: column;
	grid-template-columns: auto minmax(300px, 1fr) 160px;
	align-items: center;

	@include lt-xs {
		grid-auto-flow: row;
		grid-template-columns: auto;
	}

	.platform-search-bar-wrapper {
		grid-template-columns: auto 1fr;
	}
}

.platform-search {
	grid-auto-flow: column;
	height: 60px;
	z-index: 1;
	grid-template-columns: minmax(0, 1fr) auto;
	transition: all 300ms ease-in-out;

	&__back {
		background-color: inherit;
	}

	&__input {
		height: 50px !important;
		border: none !important;
		min-width: 70px;
		color: $brownish-grey;

		&:focus-visible {
			outline: none;
		}
	}

	&__search-card {
		z-index: 2;
		top: 65px;
		padding: 20px 15px 15px;
		grid-template-columns: 1fr;
		justify-items: flex-start;
	}

	&__result-card {
		&--not-found,
		&--error,
		&--loading {
			height: 200px;
		}

		&__chips {
			padding-bottom: 20px;
			border-bottom: 1px solid $very-light-pink-3;

			&__chip-list {
				display: flex;
				flex-wrap: wrap;
				gap: 5px;
			}
		}

		&__item {
			display: grid;
			grid-template-columns: 1fr;

			&:not(:last-child) {
				border-bottom: 1px solid $very-light-pink-3;
			}

			p {
				margin: 0;
			}
		}
	}
}
