@import '~base/scss/vars';

.product-filter {
	&__vehicle-type {
		max-width: 70px;

		&:hover {
			cursor: pointer;
		}
	}

	&__category {
		&--selected {
			border-right: 4px solid $azure;
			font-family: 'DaimlerCS-Demi', sans-serif;
		}
	}

	&__part-type {
		&__icon {
			color: $brown-grey;

			&--active {
				&:hover {
					color: $azure;
				}
			}
		}
	}
}

/* stylelint-disable */
div[class='product-filter__part-type__checkbox checkbox checkbox--content-row']
	label[class*='checkbox__label primary'] {
	align-items: flex-end;
	justify-content: center;
	max-width: 9vw;
	display: flex;
	word-break: break-word;
	flex-shrink: 0;
}
