@import '~base/scss/_breakpoints.scss';

$box-shadow-value: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
$space-between-advantage-items: 40px;

@mixin grid-row {
	@media only screen and (max-width: 650px) {
		display: grid;
		grid-template-columns: auto;
		grid-column: auto;
		grid-auto-flow: row;

		.button {
			width: 100%;
		}
	}
}

.advantages {
	box-shadow: $box-shadow-value;
	display: grid;
	grid-template-columns: repeat(6, 1fr);

	@include grid-row;

	&__title,
	&__subtitle,
	&__button,
	&__icon-list {
		grid-column: 2 / 6;
		gap: $space-between-advantage-items;

		@include grid-row;
	}

	&__icon-list {
		display: flex;

		@include lt-xs {
			flex-wrap: wrap;
		}
	}

	&__item {
		flex-grow: 1;
		flex-basis: 0;
	}
}
