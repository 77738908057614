@import '~base/scss/vars';

.color-selection-content {
	&__header-line {
		display: grid;

		&--multiple {
			grid-template-columns: 3.5vw 3.5vw 14vw 16vw 7vw 16vw 4vw 7vw;
		}

		&--single {
			grid-template-columns: 4vw 10vw 15vw 10vw 15vw 9vw 8vw;
		}
	}

	&__text {
		color: $brownish-grey;
		font-size: 16px;
	}

	&__legacy-text {
		white-space: pre-wrap;
	}
}
