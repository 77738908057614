@import '~base/scss/_breakpoints.scss';

.product-page {
	&-container {
		.slick-list .slick-track {
			display: flex;
			gap: 1px;
		}
	}

	&__wrapper {
		display: grid;
		grid-template-columns: 6fr 1fr 5fr;
		grid-template-areas: 'left . right';

		@include lt-xs {
			grid-template-columns: 1fr;
			grid-template-areas: 'right' 'left';
			gap: 20px;
		}
	}

	&__left {
		grid-area: left;
		display: grid;

		.image-slider {
			min-width: 0;
		}

		@include lt-xs {
			.image-slider {
				padding-bottom: 10px;
			}
		}
	}

	&__right {
		grid-area: right;
	}
}
