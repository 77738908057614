.add-vehicle {
	&__container,
	&__content {
		display: grid;
		grid-gap: 25px;
	}

	&__content {
		grid-template-columns: repeat(3, minmax(33.33%, 40%));
		grid-auto-flow: column;
	}
}
