@import '~base/scss/_vars.scss';

.admin-disabled {
	&__icon {
		&:hover {
			color: $cerulean;
		}
	}

	&__content {
		svg,
		div {
			color: $black-two !important;
			cursor: default !important;

			&:hover {
				color: $black-two !important;
				background-color: unset;
			}
		}

		&:hover {
			color: $black-two !important;
		}
	}
}
