@import '~base/scss/_vars.scss';

.fast-availability {
	&-container {
		flex-wrap: nowrap;
		display: grid;
		max-height: 700px;
	}

	&__header {
		word-break: keep-all;
	}

	&__section__title {
		grid-template-columns: 1fr 1fr;

		&__quantity {
			grid-template-columns: 1.5fr 0.5fr 3.5fr;
		}
	}

	&__wrapper {
		border-bottom: 1px solid $asphalt;
	}

	&__error {
		text-align: center;
	}
}
