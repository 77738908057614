@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

$space-between-sections: 80px;
$space-between-boxes: 40px;
$space-between-cards: 25px;

@mixin image-cover {
	aspect-ratio: 16 / 9;
	object-fit: cover;
}

.reman-parts {
	gap: $space-between-sections;

	&__search {
		z-index: 1;

		&__wrapper {
			max-width: 450px;
		}

		&--error {
			border: solid 1px $blood-red;
		}

		&__error-text {
			min-height: 25px;
		}

		&__input {
			border: none !important;
			background-color: $white !important;

			&::placeholder {
				color: $black-two !important;
				font-style: normal !important;
			}
		}
	}

	&__header {
		grid-auto-flow: column;
		grid-template-columns: 3fr 2fr;

		&__search {
			margin-left: auto;
		}

		&__vehicles {
			gap: calc($grid-spacing-large * 2);

			@include lt-xs {
				flex-wrap: wrap;
			}
		}

		&__vehicle-info {
			flex-grow: 1;

			img {
				height: 172px;
				width: 172px;
				object-fit: cover;
			}
		}
	}

	&__product-wrapper {
		grid-template-columns: repeat(3, minmax(200px, 1fr));

		@include xs {
			grid-template-columns: repeat(2, minmax(200px, 1fr));
		}

		@include xxs {
			grid-template-columns: minmax(250px, 1fr);
		}
	}

	&__product-image {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
		}
	}

	&__suggestion-container {
		grid-template-columns: repeat(3, 1fr);
		gap: $space-between-boxes;
	}

	&__purchase-button {
		align-items: flex-end;
		margin-top: auto;
	}

	&__suggestion-slider {
		.slick-arrow {
			align-self: center;
		}

		.slick-arrow::before {
			display: none;
		}

		.slik-slider {
			display: flex;
		}

		.slick-slide,
		.slick-active {
			box-shadow: 0 1px 4px 0 rgb(0 0 0 / 40%);
			display: flex;
		}

		.slick-list {
			display: flex;
			overflow: hidden;
		}

		.slick-track {
			display: flex;
			gap: $space-between-boxes;
			padding: 15px;
		}
	}
}
