@import '~base/scss/vars';

.faq-navigation {
	&__main-category {
		.accordion-item__title {
			padding-right: 25px;
		}

		&__title {
			&:hover {
				color: $azure;
			}
		}

		&--open {
			font-family: 'DaimlerCS-Demi' !important;
		}

		&--active {
			color: $azure !important;
		}
	}

	&__subcategory {
		&:hover {
			color: $azure;
		}

		&--open {
			font-family: 'DaimlerCS-Demi' !important;
		}

		&--active {
			color: $azure !important;
			font-family: 'DaimlerCS-Demi' !important;
		}
	}
}

.attach-button {
	&:hover {
		background-color: $black-three;

		p {
			color: $white;
		}

		.icon {
			color: $white;
		}
	}
}
