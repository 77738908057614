@import '~base/scss/_breakpoints.scss';
@import '~base/scss/_vars.scss';

.part-sub-group {
	@include sm {
		grid-row-start: 1;
		grid-column-start: 1;
		background-color: $white;
		z-index: 1;
		padding-left: 65px;
	}
}
