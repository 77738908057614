@import '~base/scss/vars';

.code-validity {
	&__button {
		word-break: break-all;
	}

	&__color-code {
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 50%;
		display: block;
		overflow: hidden;
	}
}
