@import '~base/scss/_vars.scss';

.date-picker__content {
	z-index: 110;
}

.service-documentation-modal {
	&__content {
		display: grid;
		gap: $grid-spacing-large;
		grid-template-columns: 1fr 3fr;

		.documentation-form {
			display: grid;
			gap: $grid-spacing-large;
			overflow: auto;
			max-height: calc(100vh - 330px);

			&__section {
				display: grid;
				gap: calc(2 * #{$grid-spacing-large});
				grid-template-columns: minmax(250px, 1fr) minmax(250px, 1fr) 1fr;

				.align-top {
					align-self: flex-start;
				}
			}
		}
	}
}
