@import '~base/scss/_vars.scss';

$description-max-width: 500px;
$grid-spacing-xsmall: 5px;
$grid-spacing-medium: calc(#{$grid-spacing-small} * 2);
$arrow-width: 54px;

@mixin grid-centered {
	display: grid;
	align-items: center;
}

@mixin approved-dealer-item-wrapper {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr $arrow-width;
}

@mixin approved-dealer-item-content-wrapper {
	grid-template-columns: 6.5fr 6.5fr 8fr;
}

.mb-retailers {
	&__loading,
	&__no-retailer,
	&__error,
	&__pagination {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	&__no-retailer {
		&__description {
			margin: 0 auto;
			max-width: $description-max-width;
			padding-bottom: $grid-spacing-small;
		}
	}

	.approved-dealers {
		display: grid;

		&__titles {
			display: grid;
			grid-template-columns: 6.5fr 6.5fr 8fr $arrow-width;
			grid-template-rows: auto;
		}

		&__add-dealer {
			&:hover {
				color: $cerulean;

				.approved-dealers__add-dealer__icon {
					background-color: $cerulean;
				}
			}
		}

		.approved-dealer {
			@include grid-centered;
			@include approved-dealer-item-content-wrapper;

			grid-auto-flow: column;
			gap: $grid-spacing-small;

			&__item {
				&:nth-child(odd) {
					background-color: $white-smoke;
				}
			}

			&--collapsible {
				.accordion-item__title {
					@include approved-dealer-item-wrapper;

					.approved-dealer {
						@include approved-dealer-item-content-wrapper;
					}
				}
			}

			&__link-box {
				padding-left: calc(30px + var(--gap-base) * 2);
			}

			&__addresses {
				margin-top: 20px;
			}

			&__delivery-address,
			&__titles {
				display: grid;
				gap: 5px;
				grid-template-columns: 1fr 4fr;
			}

			&__selection {
				span {
					margin-right: unset;
				}
			}
		}
	}

	.requested-dealers {
		display: grid;

		.requested-dealer {
			align-content: center;

			&:nth-child(odd) {
				background-color: $white-smoke;
			}

			&__hide {
				margin-right: 10px;

				&:hover {
					color: $azure;
				}
			}

			&__info {
				align-items: center;
			}

			&__info,
			&__status {
				flex: 0 1 100%;
			}
		}
	}
}
