@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

.menu-item {
	max-height: 400px;
	overflow: hidden;
	background-color: $white;
	padding: 10px 40px 10px 20px;
	margin: 5px 10px;

	&[data-ppv-item='true'] {
		margin: 5px 10px 20px 10px;
		border: solid 1px $very-light-pink-3;
		border-radius: 4px;

		&:hover {
			border: solid 1px $azure;
			background-color: $white;
		}

		.menu-item__icon {
			background-color: $very-light-pink-3;
			border-radius: 2px;
		}
	}

	&--active {
		background-color: $white-smoke;
	}

	&__link {
		color: $black-two;
		text-decoration: none;
	}

	&__icon {
		align-self: start;
	}

	&__title {
		line-height: $icon-size-medium;
		font-size: 18px;
	}

	&__description {
		font-size: 16px;
	}

	@include lt-xxs {
		display: none;

		&[data-mobile-available='true'] {
			display: block;
		}
	}

	&:hover {
		cursor: pointer;
		background-color: $white-smoke;

		.menu-item__right-arrow-icon {
			transform: translateX(10px);
		}
	}

	&__right-arrow-icon {
		transition: transform 250ms;
	}
}
