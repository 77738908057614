@import '~base/scss/_vars.scss';

.alternate-part-modal {
	max-width: 1250px;

	&__items-header {
		display: grid;
		grid-template-columns: 6.8fr 3.2fr;
	}

	&__header-with-icon:hover {
		color: $azure;

		span {
			color: $azure;
		}
	}

	&__item {
		display: grid;
		grid-template-columns: 6fr 0.8fr 0.25fr 2.95fr;
		background-color: $white-smoke;
	}

	&__item-shopping-details {
		display: grid;
		grid-template-rows: 2fr 1fr;
	}

	&__item-addition {
		display: grid;
		grid-template-columns: 4fr 2fr 4fr;
	}
}
