@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

@mixin listVariantBlackVersion() {
	&[data-list-variant-black='true'] {
		@content;
	}
}

@mixin listVariantWhiteVersion() {
	&[data-list-variant-white='true'] {
		@content;
	}
}

@mixin listVariantWhiteCatalogVersion() {
	&[data-list-variant-white-catalog='true'] {
		@content;
	}
}

.header__langs {
	position: relative;

	&--active {
		flex-wrap: nowrap;
		justify-content: flex-end;

		@include xxs {
			justify-content: flex-start;
		}
	}

	&__lang {
		position: relative;
		word-break: keep-all;

		&.selected {
			font-weight: $font-weight-bold;
		}

		&.unselected {
			margin-left: 26px;
		}
	}

	&__menu {
		position: relative;
		max-height: 450px;
		z-index: 6;

		@include listVariantBlackVersion {
			overflow-x: hidden;
			overflow-y: auto;
		}

		@include listVariantWhiteVersion {
			&::before {
				content: '';
				position: absolute;

				@extend %dropdown-up-triangle;
			}

			@include listVariantWhiteCatalogVersion {
				overflow-x: hidden;
				overflow-y: auto;
			}
		}
	}

	&__wrapper {
		position: absolute;
		width: 172px;
		top: 40px;
		z-index: 3;

		@include lt-sm {
			width: 140px;
		}

		@include lt-xxs {
			transform: unset;
		}

		@include listVariantBlackVersion {
			&::before {
				content: '';
				position: absolute;

				@extend %dropdown-up-triangle;
			}

			border-color: $black-two;
			max-height: 450px;
			transform: translateX(calc(-120% + 20px)) translateY(-50%);
			right: -120px;
		}

		@include listVariantWhiteVersion {
			transform: translateX(calc(-50% + 20px));
			border-color: $white;

			@include lt-xxs {
				transform: unset;
			}

			@include listVariantWhiteCatalogVersion {
				right: -90px;
			}
		}
	}
}

%dropdown-up-triangle {
	width: 20px;
	height: 10px;

	@include lt-xxs {
		left: 10px;
	}

	@include listVariantBlackVersion {
		background: $black-two;
		clip-path: polygon(0 0, 50% 50%, 0 100%);
		left: calc(100%);
		top: calc(50% - 30px);
		z-index: 1;

		@include lt-xxs {
			left: 10px;
		}
	}

	@include listVariantWhiteVersion {
		clip-path: polygon(50% 0, 100% 100%, 0 100%);
		left: calc(50% - 10px);
		top: -10px;
		background: $white;

		@include lt-xxs {
			left: 10px;
		}
	}
}
