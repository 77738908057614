@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

.header {
	height: 76px;
	background-color: $black;
	grid-template-areas: 'logo left-content right-content';
	grid-template-columns: 1fr;

	@include lt-sm {
		height: 76px;
		grid-template-areas: 'left-content logo right-content';
		grid-auto-flow: column;
		grid-template-columns: 3fr 1fr 3fr;
	}

	@include lt-xxs {
		grid-template-columns: 2fr 1fr 2fr;
		grid-template-areas: 'left-content logo right-content';
	}

	&__container {
		max-height: 100vh;
		transition: max-height 750ms ease-in-out;

		&[data-hidden='true'] {
			max-height: 0;
			overflow: hidden;
		}
	}

	&__logo {
		width: inherit;
		grid-area: logo;
		height: 45px;

		@include gt-sm {
			min-width: 180px;
			margin-left: 45px;
		}

		@include lt-sm {
			justify-self: center;
			height: 37px;
			min-width: 50px;
		}

		&__img {
			background-image: url('@Static/images/logo-star-mercedes-benz.svg');
			background-size: contain;
			background-repeat: no-repeat;

			@include lt-sm {
				background-image: url('@Static/images/logo-compact.svg');
				background-position: center;
			}

			@include lt-xxs {
				background-position: center;
				background-image: url('@Static/images/logo-compact.svg');
			}
		}
	}

	&__left-content {
		grid-area: left-content;
		grid-auto-flow: column;
		justify-self: flex-end;

		@include lt-sm {
			justify-self: flex-start;
		}

		&__links {
			display: flex;

			@include lt-xs {
				display: none;
			}
		}

		&__language {
			display: none;

			@include lt-xs {
				display: flex;
				align-items: center;
			}
		}

		@include lt-xxs {
			transition: all 150ms ease-in-out;
			visibility: visible;

			&[data-flyout-active='true'] {
				visibility: hidden;
				opacity: 0;
				transform: translateX(-20px);
			}
		}
	}

	&__right-content {
		grid-area: right-content;
		justify-self: flex-end;
		grid-auto-flow: column;

		&__links {
			@include lt-xs {
				display: none;
			}
		}

		&__language {
			@include lt-xs {
				display: none;
			}
		}

		@include lt-xxs {
			transition: all 150ms ease-in-out;
			visibility: visible;

			&[data-flyout-active='true'] {
				max-width: 0;
				visibility: hidden;
				opacity: 0;
				transform: translateX(20px);
			}
		}

		&__flytout-close {
			visibility: hidden;
			opacity: 0;

			@include lt-xxs {
				&[data-flyout-active='true'] {
					max-width: unset;
					visibility: visible;
					opacity: 1;
					transform: translate(0);
				}
			}
		}
	}

	&__register {
		@include lt-md {
			display: none;
		}
	}

	&__logout {
		color: $very-light-pink-3;
	}

	&__truck-notifications {
		animation: expand-animation 700ms 1;
		padding-top: 10px;
		padding-bottom: 10px;

		@keyframes expand-animation {
			from {
				max-height: 0;
			}

			to {
				max-height: 100vh;
			}
		}
	}

	&__banner-notifications {
		animation: expand-animation 700ms 1;

		@keyframes expand-animation {
			from {
				max-height: 0;
			}

			to {
				max-height: 100vh;
			}
		}
	}
}

.header-item {
	&--standart {
		justify-content: flex-end !important;

		&::after {
			content: '|';
			color: $very-dark-grey;
			padding-left: 15px;
		}

		@include lt-sm {
			display: none;
		}
	}

	&--small {
		display: none;

		@include lt-sm {
			display: flex;

			&::after {
				content: '|';
				color: $very-dark-grey;
				padding-left: 15px;
				align-self: center;
			}
		}
	}
}
