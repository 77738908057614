@import '~base/scss/_vars.scss';

.header {
	&__notifications__badge {
		z-index: 9997;

		.badge-with-icon__icon:hover {
			path:last-child {
				fill: $azure;
			}
		}

		&__icon {
			z-index: 9999;
		}

		&.active {
			z-index: 9999;
		}
	}
}
