.service-information {
	&__wide {
		grid-column: 1/3;
	}

	&__workshop-checkbox {
		padding-top: 12px;
	}

	&__checkbox {
		margin-left: -50px;
	}
}
