@import 'base/scss/vars';

.recommended-parts-modal {
	background-color: $white-smoke !important;

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&__main-part {
			display: flex;
			flex-direction: column;
			width: 100%;

			&__title {
				display: flex;
				justify-content: flex-start;
				align-items: end;
				width: 100%;
			}
		}

		&__recommended-parts {
			display: flex;
			flex-direction: column;
			background-color: $white;
			width: 100%;

			&__title {
				display: grid;
				grid-template-columns: minmax(7em, 14fr) minmax(80px, 2fr) 33px minmax(290px, 10fr);
				grid-template-areas: 'info-text price quantity shoppingDetails';
				gap: 3em;

				&__info-text {
					grid-area: info-text;
				}

				&__price {
					grid-area: price;
				}

				&__quantity {
					grid-area: quantity;
				}
			}

			&__part-list {
				gap: 5px;
				display: flex;
				flex-direction: column;
			}
		}
	}
}
