@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

.subgroup-list {
	min-width: 300px;

	&__item {
		&:hover {
			cursor: pointer;
			color: $azure;
		}

		&-data {
			transition: all 0.15s ease-in;
		}

		&__label {
			align-items: flex-start;
			min-width: 0;

			&-text {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				min-width: 0;
			}
		}
	}

	.part-availability {
		align-items: start;
		grid-row: 1 / span 1;
		grid-column: 2 / span 1;
	}
}
