@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

.satisfaction-score-model {
	top: 83%;
	right: 2%;
	bottom: 2%;
	border-radius: 4px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.23);
	background-color: $white;
	position: fixed;
	z-index: 9999;
	opacity: 0;
	transition: transform 0.3s ease-in-out, opacity 0.3s linear;
	animation: left-to-right-satisfaction-score-model 0.3s;

	&[data-display='false'] {
		display: none;
	}

	&.show {
		transition: transform 0.3s ease-in-out;
		animation: right-to-left-satisfaction-score-model 0.3s;
		opacity: 1;
	}

	&__radio-icon {
		width: 36px;
		height: 36px;
	}
}

@keyframes right-to-left-satisfaction-score-model {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0);
	}
}

@keyframes left-to-right-satisfaction-score-model {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(100%);
	}
}
