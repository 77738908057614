@import '~base/scss/vars';

.division-card {
	padding: 20px 20px 10px 20px;
	height: 320px;
	flex: 1;

	&--active {
		outline: solid 1px $very-light-pink-3;
		outline-offset: -1px;
	}

	&__dropdown {
		max-width: 400px;
		width: 100%;
		padding-bottom: 10px;
	}

	&__show-options {
		height: 100%;

		&:hover {
			color: $azure;
		}
	}
}

.division-card__menu {
	border: solid 1px $very-light-pink-3 !important;
	padding: 10px 10px 10px 0;
}

.division-card__menu-list {
	border: none !important;
}

.division-card__option {
	font-size: 16px !important;
	padding-left: 10px !important;

	&:hover {
		color: $black-two !important;
		background-color: $white-smoke !important;
	}
}

.division-card__single-value {
	font-size: 16px !important;
}

.vehicle-search {
	justify-content: flex-end;

	&__input {
		width: 240px;
	}

	&__error {
		top: '100%';
		left: 0;
	}
}

.search-header {
	justify-content: flex-end;
}

.search-button {
	margin-right: 20px;
}
