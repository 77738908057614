@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

$vehicle-card-min-width: 520px;
$vehicle-card-max-width: 550px;

.vehicle-card {
	height: 100vh;
	position: sticky;
	top: 60px;

	&__toggle {
		cursor: pointer;
		position: absolute;
		right: 12px;
		top: 50%;
		height: 45px;
		max-width: 45px;
		gap: 15px;
		z-index: 3;
		white-space: nowrap;
		overflow: hidden;
		transition: right 0.2s ease-in;

		&[data-vehicle-card-visible='true'] {
			max-width: 45px;
			right: $vehicle-card-max-width;

			@include lt-lg {
				right: $vehicle-card-min-width;
			}
		}

		&:hover {
			background-color: $cerulean;
		}

		&:hover:not([data-vehicle-card-visible='true']) {
			max-width: 400px;
			transition: all 0.2s;
		}
	}

	&__content {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 4;
		background-color: $white;
		height: inherit;
		overflow-x: hidden;
		display: flex;
		flex-direction: column;
		gap: 25px;
		transform: translateX(100%);
		transition: all 0.2s ease-out;
		width: $vehicle-card-max-width;
		opacity: 0;
		padding: 10px;

		@include gt-lg {
			padding: 20px;
		}

		@include lt-lg {
			width: $vehicle-card-min-width;
			padding: 10px;
		}

		&--active {
			transform: translateX(0);
			opacity: 1;
			transition: transform 0.2s ease-in;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);
		}
	}

	.text-area__placeholder {
		display: none;
	}
}

.modal-open {
	.vehicle-card {
		z-index: 0;
		opacity: 0;
	}
}
