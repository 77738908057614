@import '~base/scss/_breakpoints.scss';
@import '~base/scss/_vars.scss';

.public-home {
	gap: 80px;

	&__banner {
		position: relative;
		display: inline-block;
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 450px;
		height: fit-content;
		width: 100vw;

		@include lt-sm {
			height: 500px;
		}

		@include lt-xxs {
			height: 785px;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100%;
			background: linear-gradient(47deg, rgba(0, 0, 0, 0.8) 26%, rgba(0, 0, 0, 0.2) 60%);
		}

		&__content {
			height: 100%;
			display: grid;
			place-content: center;
			position: absolute;
			top: 0;
			z-index: 2;
			grid-template-rows: 11fr 1fr;

			.cms-content {
				p {
					margin: 0;
				}

				@include gt-xxs {
					width: 70%;
				}
			}

			@include lt-xxs {
				padding: 40px 20px;
				place-content: space-between;
			}
		}

		&__register {
			display: flex;
			gap: 10px;

			@include lt-xxs {
				flex-flow: column-reverse;
			}
		}
	}

	&__register {
		position: fixed;
		z-index: 4;
		top: 20px;
		right: 20px;
		display: flex;
		visibility: hidden;
		justify-content: center;
		transform: translateY(-100px);
		transition: all 350ms ease-in-out;

		&[data-visible='true'] {
			visibility: visible;
			transform: translateY(0);
		}

		@include lt-xxs {
			display: flex;
			height: 50px;
			bottom: 10px;
			top: unset;
			right: unset;
			width: 100vw;
			z-index: 2;
			visibility: hidden;
			transform: translateY(100px);

			&[data-visible='true'] {
				visibility: visible;
				transform: translateY(0);
			}
		}

		.button {
			box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
			width: calc(100% - 20px);
		}
	}
}
