@import '~base/scss/vars';

.home__modules__header {
	justify-items: center;
	height: fit-content;
	gap: 10px;
	width: 100%;

	&__chip {
		color: $brownish-grey;
		background-color: $very-light-pink-1;
		cursor: unset;
		height: unset;
		white-space: break-spaces;
	}

	&__title {
		line-height: normal;
		max-width: 1020px;
	}
}
