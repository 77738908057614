@import '~base/scss/_vars.scss';

.basket {
	&-container {
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	}

	&-products__heading,
	&-item-container,
	&-footer {
		display: grid;
		gap: $grid-spacing-small;
		grid-auto-flow: column;
		grid-template-columns: 3fr 2fr 1fr 2fr;
	}

	&-products__heading__price,
	&-item__price {
		text-align: right;
		align-items: flex-end;
	}

	&-footer {
		&__price {
			display: grid;

			&-detail {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}

	&-item {
		&__detail {
			display: grid;
			gap: $grid-spacing-small;
			grid-template-columns: minmax(40px, 1fr) 2fr;
		}

		&__delete {
			justify-self: end;
		}
	}
}
