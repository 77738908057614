@import '~base/scss/vars';

.notification-card {
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 6fr 1fr;
	grid-template-areas:
		"icon date expand"
		". title ."
		". description .";

	&--date-unavailable {
		grid-template-areas:
			"icon title expand"
			". description .";
	}

	&--collapsible {
		&:hover {
			color: $azure;
			cursor: pointer;
			background-color: $white-two-4;

			span[class*='notification-card__expand__arrow'] {
				color: $azure;
			}
		}
	}

	&__title {
		grid-area: title;
	}

	&__description {
		grid-area: description;
		word-wrap: break-word;
		word-break: break-word;

		&__expanded {
			max-height: 200vh;
			transition: max-height 0.3s ease-in;

			&.hidden {
				overflow: hidden;
				max-height: 0;
				transition: max-height 0.3s ease-out;
			}
		}
	}

	&__date {
		grid-area: date;
	}

	&__icon {
		grid-area: icon;
	}

	&__expand {
		grid-area: expand;
	}

	&__icon,
	&__expand {
		display: grid;
		justify-content: center;
	}

	.cms-content {
		p {
			margin: 0;
		}
	}
}
