@import '~base/scss/vars';

.dsb-detail-modal {
	&__content-wrapper {
		height: 100%;

		& > :first-child {
			display: grid;
			height: 100%;
		}
	}

	&__content {
		display: grid;
		gap: 50px;
		grid-template-columns: 1fr 1fr;
		padding: 0 15px;

		.oil-change {
			&__title,
			&__item {
				display: grid;
				grid-auto-columns: 1fr 1fr;
				grid-auto-flow: column;
			}
		}

		.oil-change__item:last-child,
		.additional-work-item:last-child {
			border-bottom: 1px solid $asphalt;
		}
	}

	&__details {
		display: grid;
		gap: 10px;
		grid-template-columns: 1.3fr 2fr;
	}

	&__button-container {
		display: flex;
		flex-direction: row;
		width: 100%;
	}
}
