.webparts-order-modal {
	grid-auto-flow: column;
	grid-template-columns: 1fr 4fr;

	&__description {
		max-width: 900px;
	}

	.order {
		max-width: 900px;

		&-detail {
			border-radius: 4px;
			background-color: #f4f4f4;
			display: flex;
			align-content: center;
		}
	}

	&--error {
		grid-auto-flow: column;
	}
}
