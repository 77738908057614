$space-in-middle-of-sections: 40px;

@mixin grid-row {
	@media only screen and (max-width: 650px) {
		grid-template-columns: auto;
		grid-column: auto;
		grid-auto-flow: row;
	}
}

@mixin image-cover {
	aspect-ratio: 16 / 9;
	object-fit: cover;
}

.communication-event {
	&__feature-section {
		gap: $space-in-middle-of-sections;
		grid-template-columns: repeat(2, 1fr);

		@include grid-row;

		img {
			@include image-cover;
		}
	}
}
