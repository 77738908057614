@import '~base/scss/vars';

.standardized-navigation {
	[class^='accordion-item'] {
		div[class*='accordion-item__title'] {
			padding-right: 20px;
		}
	}

	.main-category {
		font-family: DaimlerCS-Regular;
		color: $black-three;

		&:hover {
			color: $azure;

			&[class^='accordion-item'] {
				span[class*='accordion-item__arrow'] {
					color: $azure;
				}
			}
		}

		&__open {
			&--active {
				color: $azure;

				&[class^='accordion-item'] {
					span[class*='accordion-item__arrow'] {
						color: $azure;
					}
				}
			}

			&--inactive {
				font-family: DaimlerCS-Demi;
				color: $black-three;
			}
		}
	}

	.sub-category {
		font-family: DaimlerCS-Regular;
		color: $black-three;

		&:hover {
			color: $azure;
		}

		&--active {
			color: $azure;
		}
	}
}
