@import '~base/scss/_breakpoints.scss';
@import '~base/scss/_vars.scss';

.main-group {
	@include sm {
		width: fit-content;
		grid-row-start: 1;
		grid-column-start: 1;
		background-color: $white;
		z-index: 2;
	}
}
