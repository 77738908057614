@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

$space-between-sections: 80px;
$space-between-boxes: 40px;
$space-between-cards: 25px;

.genuine-parts {
	gap: $space-between-sections;

	&__header-section {
		background-size: cover !important;
		background-repeat: no-repeat !important;
		padding-bottom: 55px;
		min-height: 70vh;

		&__content {
			flex-direction: column;
			gap: 20px;
		}

		&__title,
		&__subtitle {
			width: 60%;
		}

		&__button {
			width: fit-content;
			padding: 10px;
		}
	}
}
