@import '~base/scss/_vars.scss';

.model-range-selection {
	&__header {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-auto-flow: row;
		gap: 10px;
		padding-bottom: 5px;
		border-bottom: 1px solid $very-light-pink-3;
	}

	&__item {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-auto-flow: row;
		gap: 10px;
		border-bottom: 1px solid $very-light-pink-3;

		&:hover {
			cursor: pointer;
			background-color: $very-light-pink-1;
		}
	}
}
