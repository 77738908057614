@import '~base/scss/_breakpoints.scss';
@import '~base/scss/_vars.scss';

.package-list {
	flex-grow: 1;
	min-width: 0;

	@include sm {
		grid-row-start: 1;
		grid-column-start: 1;
		background-color: $white;
		z-index: 1;
	}

	&__item {
		&:not(:last-of-type) {
			border-bottom: 1px solid $very-light-pink-3;
		}
	}
}
