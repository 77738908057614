@import '~base/scss/_breakpoints.scss';
$space-in-middle-of-sections: 40px;

@mixin grid-row {
	@media only screen and (max-width: 650px) {
		grid-template-columns: auto;
		grid-column: auto;
		grid-auto-flow: row;
	}
}

.division-section {
	gap: $space-in-middle-of-sections;
	grid-template-columns: repeat(2, 1fr);

	@include grid-row;
}

.description-section {
	max-height: 300vh;
	transition: max-height 300ms ease-in-out;
	overflow: hidden;

	&--visible {
		max-height: 0;
	}
}

.video-section {
	grid-template-columns: 1fr 1fr;
	grid-auto-flow: column;
	gap: $space-in-middle-of-sections;

	@include lt-xs {
		grid-auto-flow: row;
		grid-template-columns: 1fr;
	}

	&__video {
		&--reverse {
			grid-column-end: -1;
		}
	}
}
