.cookie-settings {
	&__section {
		width: 100%;
	}

	.category-title {
		display: grid;
		grid-template-columns: 3fr 1fr 0.7fr 1fr;
	}

	.cookie {
		&-title {
			display: grid;
			grid-template-columns: 0.2fr 2.8fr 1fr 0.7fr 1fr;
		}

		&-description {
			display: grid;
			grid-template-columns: 0.2fr 3fr 2.5fr;
			white-space: break-spaces;

			.cookie-id {
				width: 100px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				justify-self: flex-start;
			}
		}
	}
}
