@import '~base/scss/vars';

$transition-duration: 0.2s;
$notifications-menu-wrapper-transition: height $transition-duration, transform $transition-duration;
$notifications-menu-curtain-transition: opacity $transition-duration ease-in-out;

.notifications-menu {
	position: absolute;
	top: 0;

	&__availability {
		&--hidden {
			display: none;
		}
	}

	&__wrapper {
		position: sticky;
		top: 3vh;
		z-index: 9998;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		background-color: $white;
		border-radius: 7px;
		transform: translate(0, 5vh);
		width: 446px;

		&::before {
			content: '';
			position: absolute;
			background-color: $white;
			height: 9px;
			width: 17px;
			right: 15px;
			top: -8px;
			clip-path: polygon(50% 0, 0 100%, 100% 100%);
		}

		&--closed {
			transition: $notifications-menu-wrapper-transition;
			height: 10vh;
			overflow: hidden;
		}

		&--open {
			transition: $notifications-menu-wrapper-transition;
			position: sticky;
			height: calc(90vh - 30px);
			display: flex;
			justify-content: flex-start;
			z-index: 9999;
		}

		&--right {
			&::before {
				right: 0;
				left: 15px;
			}
		}
	}

	@keyframes slideopen {
		from {
			max-height: 50%;
		}

		to {
			max-height: 100%;
		}
	}

	@keyframes slideclose {
		from {
			max-height: 100%;
		}

		to {
			max-height: 0%;
		}
	}

	&__curtain {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9997;
		background-color: $black;
		opacity: 0;
		transition: $notifications-menu-curtain-transition;
	}

	&__content {
		&__item {
			&--loading {
				border-radius: 4px;
				background-color: $asphalt;
				overflow: hidden;
			}

			&-animation {
				animation-name: ShiningAnimation;
				animation-duration: 4s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				background-image: linear-gradient(90deg, transparent, $white, transparent);
			}
		}
	}

	&__notification {
		&-items {
			visibility: hidden;
			overflow-y: scroll;
			height: 91%;

			&.active {
				transition: $notifications-menu-wrapper-transition;
				transform: translate(0, 2vh);
				visibility: visible;
			}
		}
	}

	&--open {
		.notifications-menu__curtain {
			opacity: 0.6;
		}
	}

	&--left {
		left: -408px;
	}

	&--right {
		left: -8px;
	}

	&--close {
		visibility: hidden;
		transition: visibility 0.15s ease-in-out;
	}

	@keyframes ShiningAnimation {
		from {
			transform: translate(-200%);
		}

		to {
			transform: translate(200%);
		}
	}
}
