@import '~base/scss/_breakpoints.scss';

.part-packages {
	&__container {
		@include sm {
			display: grid;
			grid-template-columns: 1fr;
		}
	}
}
