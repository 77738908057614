.dealer-locator {
	&__modal {
		.modal__content {
			padding: unset;
			height: 100%;

			div:first-child {
				width: 100%;
			}
		}
	}
}
