@import '~base/scss/vars';

.field-measures {
	scroll-margin: 100px;

	&__title,
	&__item {
		display: grid;
		gap: $grid-spacing-small;
		grid-auto-flow: column;
		grid-template-columns: 5fr 5fr;
		align-items: center;
	}

	&__item {
		position: relative;
		color: $black-two;
		min-height: 34px;
	}

	&__item:nth-child(2n-1) {
		background-color: $white-smoke;
	}

	&__item:nth-child(2n) {
		background-color: $white;
	}
}
