@import '~base/scss/vars';

.technical-data {
	&__header {
		&.item-title {
			color: $brown-grey;

			&--active {
				color: $white;
			}

			&:hover {
				color: $white;

				.icon {
					color: $white;
				}
			}
		}

		&-hr {
			border-top-width: 1px;
			border-top-style: solid;
			border-color: $azure;
		}
	}

	&__body {
		.register-button {
			&.button {
				&:hover {
					color: $white;
					background-color: $brownish-grey;
				}
			}
		}
	}

	&__child-item {
		color: $black-two;
		cursor: pointer;

		&:hover {
			color: $black;
			font-family: DaimlerCS-Demi;
		}

		&--active {
			color: $black;
			font-family: DaimlerCS-Demi;
		}

		&--not-clickable {
			opacity: 0.4;
		}
	}

	&__sub-item {
		span {
			flex-shrink: 0;
		}
	}
}
