@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

$repair-page-min-height: 350px;
$repair-page-height--large: 440px;
$repair-bg-width--large: 390px;
$repair-bg-width--small: 285px;

$search-width--default: 700px;
$search-width--small: 600px;
$search-width--large: 800px;

.vehicle-repair {
	position: relative;
	z-index: 1;

	&__search {
		width: $search-width--default;

		@include lt-sm {
			width: $search-width--small;
		}

		@include gt-uhd {
			width: $search-width--large;
		}

		&[data-search-init='true'] {
			width: 100%;
		}
	}

	&__title {
		max-height: 100vh;
		overflow: hidden;
		transition: max-height 400ms ease-in-out;

		&[data-search-init='true'] {
			max-height: 0;
		}
	}

	&__background {
		z-index: -1;
		position: absolute;
		right: 0;
		top: 20px;
		background-image: url('@Static/images/info-search.svg');
		background-position: right;
		background-repeat: no-repeat;
		width: $repair-bg-width--small;
		background-size: $repair-bg-width--small;
		min-height: $repair-page-min-height;
		transition: opacity 300ms ease-in-out;

		@include lt-sm {
			top: 50px;
		}

		@include gt-lg {
			width: $repair-bg-width--large;
			background-size: $repair-bg-width--large;
			min-height: $repair-page-height--large;
		}

		&[data-hidden='true'] {
			opacity: 0;
		}
	}
}
