@import '~base/scss/vars';

.search-suggestion-item {
	display: flex;
	align-items: center;
	cursor: pointer;

	&:hover {
		color: $azure;
		background-color: $white-smoke;
	}
}
