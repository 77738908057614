@import '~base/scss/_vars.scss';

.text-search-modal {
	&-container {
		flex-wrap: nowrap;
		max-height: 700px;
		align-items: center;
	}

	&__header {
		word-break: keep-all;
	}

	&__under-header {
		align-items: center;
	}

	&__filter {
		align-items: center;
		flex-wrap: wrap;
	}

	&__search {
		justify-content: end;
		flex-wrap: wrap;
	}

	&__ipart-container {
		&__item {
			flex-direction: row;
			justify-content: space-between;

			&:hover {
				background-color: $white-smoke;
			}
		}
	}

	&__filter-result {
		max-height: 450px;
		overflow-y: scroll;
	}

	&__filter-buttons {
		&--passive {
			border-radius: 12px;
			background-color: $asphalt;
			color: $black-two;
			padding: 4px 12px;
			cursor: pointer;

			&:hover {
				background-color: $black;
				color: $white;
			}
		}

		&--active {
			border-radius: 12px;
			background-color: $black;
			color: $white;
			padding: 4px 12px;
			cursor: pointer;

			&:hover {
				background-color: $black;
				color: $white;
			}
		}
	}

	&__mini-headers {
		grid-template-columns: repeat(2, 1fr);
	}

	&__mini-headers:first-child {
		grid-column: 1;
	}

	&__mini-headers:last-child {
		text-align: end;
		grid-column: 2;
	}
}
