.export-pdf-options {
	&__container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__language-selector {
		display: flex;
		flex-direction: column;
		width: 20vw;
		height: 80px;
	}

	&__language-dropdown {
		margin-top: 0.4vh;
	}

	&__selection {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__info-text {
		margin: 25px 237.5px 25px 0;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: var(--greyscale-17-wb-grey-20-wb-color-obsidian);
	}

	&__checkbox {
		width: 40px;
		height: 40px;
	}

	&__error {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
}
