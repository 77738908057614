@import '~base/scss/vars';

.flyout-notification {
	padding: 20px;
	border-radius: 4px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.23);
	background-color: $white;
	position: fixed;
	right: 10px;
	z-index: 9999;
	opacity: 0;
	transition: transform 0.3s ease-in-out, opacity 0.3s linear;
	animation: left-to-right-basket-notif 0.3s;

	&[data-display='false'] {
		display: none;
	}

	&.show {
		transition: transform 0.3s ease-in-out;
		animation: right-to-left-basket-notif 0.3s;
		opacity: 1;
	}

	.truncate {
		width: 200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@keyframes right-to-left-basket-notif {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0);
	}
}

@keyframes left-to-right-basket-notif {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(100%);
	}
}
