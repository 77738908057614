@import '~base/scss/_breakpoints.scss';
@import '~base/scss/vars';

$contentWidth: 300px;

.webparts-campaigns {
	&__container {
		min-height: 300px;
		gap: 40px;
		display: grid;
	}

	&__header {
		&__dropdown {
			width: 400px;

			@include lt-md {
				width: 300px;
			}

			@include lt-sm {
				width: 200px;
			}
		}
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		gap: 40px;

		&__description {
			flex: 4 1 $contentWidth;
		}
	}

	&__download {
		flex-basis: $contentWidth;
		flex-grow: 1;

		&__button {
			min-width: $contentWidth;
			width: inherit;

			&:hover {
				background-color: $white-smoke;
			}
		}
	}

	&__banner {
		background: url('@Static/images/campaign-background.svg'), #d8d8d8;
		background-position-x: right;
		background-repeat: repeat-y;
		background-size: contain;
		padding: 20px;
		min-height: 200px;

		div {
			max-width: 50%;

			@include lt-sm {
				max-width: 70%;
			}
		}
	}
}
