@import '~base/scss/_vars.scss';

.fast-availability__item {
	width: 100%;
	border-radius: 4px;
	grid-template-columns: repeat(2, 1fr);
	min-height: 60px;

	&--not-basket {
		grid-template-columns: 1fr 1fr;
	}

	&__detail {
		grid-template-columns: 100px 60px 100px;
		justify-content: end;

		&--not-basket {
			grid-template-columns: 1.5fr 0.5fr 2fr 0.5fr 1fr;
		}
	}
}
