@import '~base/scss/_vars.scss';

.replacement-chain-modal {
	width: 1250px;

	&__header {
		display: grid;
		grid-template-columns: 15em minmax(0, 2fr) minmax(0, 6fr) minmax(75px, 2fr) 50px minmax(250px, 10fr);
		grid-template-areas: 'position partNumber information price quantity shoppingDetails';
		gap: 1em;

		&__item {
			&__position {
				grid-area: position;
			}

			&__part-number {
				grid-area: partNumber;
			}

			&__information {
				grid-area: information;
			}

			&__price {
				grid-area: price;
			}

			&__quantity {
				grid-area: quantity;
			}

			&__shop-details {
				grid-area: shoppingDetails;
			}
		}
	}

	&__list-item {
		padding: 10px;
	}
}
