@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

$space-between-cards: 10px;
$space-between-inner-items: 20px;
$container-width: 400px;
$container-height: 300px;

.more-applications {
	grid-template-columns: repeat(4, 1fr);
	gap: $space-between-cards;

	@include lt-sm {
		grid-template-columns: repeat(3, 1fr);
	}

	@include gt-uhd {
		grid-template-columns: repeat(5, 1fr);
	}

	&__show-all-button {
		justify-self: center;
		cursor: pointer;

		&:hover {
			color: $white;
			background-color: $black-two;

			.icon {
				color: $white;
			}
		}
	}

	&__item {
		gap: $space-between-inner-items;
		grid-auto-flow: row;
		grid-template-columns: auto;
		grid-template-rows: max-content auto;
		border-radius: 4px;

		&__inner-content {
			line-height: 1.5;
			grid-auto-flow: row;
			grid-template-columns: auto;
			grid-template-rows: auto 1fr auto;
			gap: 10px;
		}

		&__button {
			justify-self: stretch;
			margin-bottom: 20px;
			height: 40px;
		}
	}
}

.custom-image {
	position: relative;
	max-width: $container-width;
	max-height: $container-height;
	overflow: hidden;
	aspect-ratio: 4 / 3;
	object-fit: cover;
	border-radius: 4px;

	&__icon-gray {
		width: 130%;
		height: 130%;
		position: absolute;
		left: -25%;
		bottom: -25%;
		opacity: 0.2;
	}

	&__icon-azure {
		width: 70%;
		height: 70%;
		bottom: -20%;
		left: -10%;
		position: absolute;
	}
}
