.lucky-draw-content {
	gap: 40px;

	& > * {
		flex: 1;
	}

	&__left-content {
		overflow-y: auto;
		max-height: 100%;
	}

	&__right-content {
		position: sticky;
		bottom: 0;
		align-self: flex-end;
	}

	&__footer {
		gap: 30px;
	}
}
