@import '~base/scss/_breakpoints.scss';
@import '~base/scss/_colors.scss';
@import '~base/scss/vars';

.ncj-footer {
	grid-template-areas:
		"logo top"
		"links news"
		"links qr"
		"legal legal";

	@include lt-sm {
		grid-template-areas:
			"logo top"
			"news news"
			"links links"
			"qr qr"
			"legal legal";
	}

	@include lt-xxs {
		grid-template-areas:
			"top"
			"logo"
			"news"
			"links"
			"legal";
	}

	&.only-legal {
		grid-template-areas:
			"logo top"
			"legal legal";

		@include lt-xxs {
			grid-template-areas:
				"top"
				"logo"
				"legal";
		}
	}

	&__logo {
		grid-area: logo;

		img {
			width: 150px;
		}
	}

	&__top {
		grid-area: top;
		justify-content: end;

		@include gt-lg {
			line-height: 1.45;
		}

		@include lt-xxs {
			justify-content: center;
		}
	}

	&__newsletter {
		grid-area: news;
		max-width: 300px;
		display: flex;
		flex-direction: column;

		&__button {
			width: 100%;
			height: fit-content;
			align-self: center;

			@include lt-sm {
				white-space: nowrap;
				width: fit-content;
			}

			@include lt-xxs {
				width: 100%;
			}
		}

		@include lt-sm {
			flex-direction: row;
			max-width: unset;
			border-bottom: 1px solid $very-dark-grey;
			padding-bottom: 30px;
		}

		@include xxs {
			flex-direction: column;
			width: 100%;
			max-width: 100%;
		}
	}

	&__qr {
		justify-content: flex-end;
		grid-area: qr;
		height: 100px;
		width: auto;

		img {
			height: 100%;
		}

		@include lt-sm {
			justify-content: flex-start;
		}

		@include lt-xxs {
			display: none;
		}
	}

	&__legal {
		grid-area: legal;

		@include lt-xs {
			flex-direction: column;
			flex-wrap: wrap;

			:first-child {
				order: 99;
				margin-top: 50px;
			}
		}

		@include gt-xs {
			:not(:last-child):not(:first-child)::after {
				content: '|';
				padding: 0 10px;
			}

			:first-child {
				margin-right: auto;
			}
		}
	}

	&__links {
		grid-area: links;
		max-width: 1200px;
		column-count: 3;

		&__wrapper {
			-webkit-column-break-after: auto;
			break-before: avoid-page;

			@include lt-xxs {
				margin-bottom: 20px;
				display: none;

				&[data-mobile-available='true'] {
					display: block;
				}
			}

			:nth-child(-n+3) {
				page-break-before: avoid;
				break-before: avoid-column;
			}

			&[data-mobile='true'] {
				@include gt-xxs {
					display: none;
				}
			}

			&[data-main-group="true"] {
				@include gt-lg {
					display: block;
					break-before: column;
					break-after: column;
					break-inside: avoid;
				}
			}
		}

		&__group {
			@include lt-xxs {
				justify-content: space-between;
				cursor: pointer;
				gap: 20px;
			}

			&__arrow {
				@include gt-xxs {
					display: none;
				}
			}
		}

		&__items {
			display: none;
			max-height: 100vh;
			transition: max-height 150ms ease-in-out, padding 150ms ease-in-out;

			@include lt-xxs {
				display: grid;
				overflow: hidden;
				gap: 20px;
				padding: 20px 0;

				&[data-collapsed='true'] {
					max-height: 0;
					padding: 0;
				}
			}

			&__link {
				@include lt-xxs {
					display: none;
				}

				&--mobile {
					&.hide {
						@include lt-xxs {
							display: none;
						}
					}
				}
			}
		}

		@include lt-hd {
			column-count: 4;
		}

		@include lt-lg {
			column-count: 3;
		}

		@include lt-xs {
			column-count: 2;
		}

		@include lt-xxs {
			column-count: 1;
		}
	}
}
