.product-card {
	height: 192px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	width: 100%;

	&__title {
		@extend %line-clamp;

		-webkit-line-clamp: 1;
	}

	&__description {
		@extend %line-clamp;

		-webkit-line-clamp: 3;
	}
}

%line-clamp {
	display: -webkit-box !important;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
