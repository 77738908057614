@import '~base/scss/_vars.scss';

.add-new-retailer__modal {
	width: 100%;

	.modal__content {
		flex-grow: 1;
		flex-shrink: 1;
	}

	.dl-dsw--container {
		max-width: unset;

		.dl-dsw {
			padding: 0;
			max-width: unset;

			h2 {
				margin-top: 15px;
			}

			form[class='dl-dealers-search__form'] {
				margin-top: 22px;
			}

			div[class*='dl-leaflet'] {
				height: unset;

				div[class*='leaflet-container'] {
					max-height: 47vh;
				}
			}
		}
	}
}

.add-company-mail-modal__content {
	margin: 0;
}

.dl-autocomplete__result-list {
	background: $white !important;
}
