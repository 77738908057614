@import '~base/scss/_vars.scss';

@mixin grid-row-item {
	grid-template-columns: 2fr 2.75fr 1.75fr 2.5fr 2.5fr;
	grid-auto-flow: column;
	gap: 30px;
}

.model-identification {
	&__modal {
		padding-left: 105px;
		padding-right: 105px;
	}

	&__container {
		min-height: 700px;
	}

	&__content {
		padding-top: 0;

		&__header {
			position: -webkit-sticky;
			position: sticky;
			top: 0;

			&__item {
				@include grid-row-item;

				padding-top: 10px;
			}
		}
	}

	&__series {
		&__item {
			&:hover {
				background-color: $white-smoke;
				cursor: pointer;
			}
		}
	}

	&__list {
		grid-template-rows: auto;
	}

	&__item {
		@include grid-row-item;

		padding: 10px 0;

		&:hover {
			background-color: $white-smoke;
			cursor: pointer;
		}
	}

	&__search {
		width: auto !important;
	}
}
