.layout {
	&__body {
		grid-template-columns: auto 1fr auto;
		grid-auto-flow: column;
		transition: transform 200ms ease-in-out;

		&[data-sidebar-disabled='true'] {
			grid-template-columns: 0 1fr;
		}

		&__content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__sidebar {
			transform: translateX(0);
			transition: all 200ms ease-in-out;
			z-index: 9 !important;

			.curtain-open &,
			.modal-open & {
				z-index: 0 !important;
			}

			&[data-sidebar-disabled='true'] {
				transform: translateX(-200px);
			}
		}
	}
}

body {
	&:has(.modal__curtain) {
		.layout__body__sidebar {
			z-index: 0;
		}
	}
}
