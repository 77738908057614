@import '~base/scss/vars';

.cms-content {
	a {
		text-decoration: none;
		cursor: pointer;
		color: $azure;

		&:hover {
			color: $cerulean;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		display: block;
		position: relative;
		font-weight: normal;
		padding: 15px 0;
	}

	&[data-modal='false'] {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			&::after {
				content: '';
				width: 30px;
				height: 2px;
				position: absolute;
				background: $black;
				top: 10px;
				left: 0;
			}
		}
	}

	h1 {
		font-size: 64px;
		font-family: 'DaimlerCAC-Regular', sans-serif;
	}

	h2 {
		font-size: 38px;
		font-family: 'DaimlerCAC-Regular', sans-serif;
	}

	h3 {
		font-size: 22px;
		font-family: 'DaimlerCS-Demi', sans-serif;
	}

	h4 {
		font-size: 20px;
		font-family: 'DaimlerCS-Regular', sans-serif;
	}

	h5 {
		font-size: 18px;
		font-family: 'DaimlerCAC-Regular', sans-serif;
	}

	h6,
	p {
		width: 100%;
		font-family: 'DaimlerCS-Regular', sans-serif;
		color: $black-two;
		margin: 25px 0;
	}

	&.theme_light {
		p {
			color: $white !important;
		}
	}

	ul {
		margin: 10px 0;
		padding-inline-start: 25px;

		li[data-type='DISC'] {
			list-style: disc;

			p {
				margin: 0;
			}
		}

		li[data-type='ICON'] {
			list-style: none;

			> section {
				span.icon {
					width: 30px;
					height: 30px;
					display: inline-block;
					vertical-align: top;
					margin-top: -5px;
					margin-left: -30px;
				}

				> p {
					margin: 0;
					font-size: 14px;
					color: $black-two;
					display: inline-block;
					vertical-align: middle;
					max-width: calc(100% - 40px);
				}
			}

			ul {
				margin-left: 5px;

				li {
					list-style: none;
					padding-left: 0;
				}
			}
		}
	}

	blockquote {
		padding: 5px 20px;
		border-radius: 5px;
		background: $white-two;
	}

	table:not(.filterable-table__table):not(.cms-table-with-icons) {
		border: 1px solid;
		border-collapse: collapse;
		font-size: 12px;

		tbody,
		thead,
		td,
		tr {
			border: inherit;
		}

		td,
		th {
			padding: 10px;
			text-align: left;
		}
	}

	.cms-table-with-icons {
		border-collapse: collapse;
		font-size: 12px;
		width: 100%;
		margin: 80px 0;

		td:first-child {
			font-family: 'DaimlerCS-Regular', sans-serif;
		}

		td,
		th {
			padding: 10px;
			text-align: center;
		}

		&.theme_default {
			td:not(:last-child) {
				border-right: 1px solid rgba(0, 0, 0, 0.04);
			}

			tr:nth-child(odd) {
				background-color: transparent;
			}

			tr:nth-child(even) {
				background-color: transparent;
			}
		}
	}

	.collapse-btn--active {
		font-family: 'DaimlerCS-Demi';
		overflow: hidden;
		text-align: center;
		color: $black-two;
		cursor: pointer;

		&:hover {
			color: $azure;
		}

		&:hover::before,
		&:hover::after {
			background-color: $azure;
		}

		&::before,
		&::after {
			background-color: $greyish-brown-2;
			content: '';
			display: inline-block;
			height: 1px;
			position: relative;
			vertical-align: middle;
			width: 50%;
		}

		&::before {
			right: 0.5em;
			margin-left: -50%;
		}

		&::after {
			left: 0.5em;
			margin-right: -50%;
		}
	}

	.link {
		display: inline-block;
		color: $black-two;
		cursor: pointer;
		text-decoration: underline;

		&:hover {
			color: $azure;
		}
	}
}

.rich-description {
	ul {
		li {
			padding: 0%;
			margin-top: 2%;
		}
	}
}
