@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

%product-suggestion-grid {
	display: grid;
	gap: 40px;
	grid-template-columns: 1fr 1fr;
}

.product-suggestions {
	&__list {
		@extend %product-suggestion-grid;

		@include lt-xs {
			grid-template-columns: 1fr;
		}
	}

	.product-suggestion-card {
		@extend %product-suggestion-grid;

		&:first-child {
			border-right: solid 1px $greyish-brown-2;

			@include lt-xs {
				border: none;
			}
		}

		&__image {
			max-height: 170px;

			img {
				object-fit: cover;
				width: 100%;
				height: auto;
			}
		}

		&__button {
			margin-left: auto;
		}
	}
}
