@import '~base/scss/_vars.scss';

.webpart-basket {
	&-header {
		border-radius: 4px;
		background-color: #f4f4f4;
		display: flex;
		align-content: center;
		flex-direction: column;
	}

	&-products__heading,
	&-item-container,
	&-products__heading__price,
	&-item__price {
		text-align: right;
		align-items: flex-end;
	}

	&-footer {
		display: grid;
		gap: $grid-spacing-small;
		grid-auto-flow: column;
		grid-template-columns: 3fr 1fr;
		background-color: $white;

		&__price {
			display: grid;
			direction: ltr;

			&-detail {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}

	&-item {
		&__detail {
			display: grid;
			gap: $grid-spacing-small;
			grid-template-columns: minmax(40px, 1fr) 2fr;
		}

		&__delete {
			justify-self: flex-end;
		}
	}
}
