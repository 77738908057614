@import '~base/scss/vars';
$search-bar-width: 595px;

.faq-search-area {
	display: flex;
	flex-direction: column;

	.search-question {
		width: $search-bar-width;
		margin-top: -25px;
		position: relative;
	}

	.search-result {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&--error {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&--successful {
			display: grid;
		}
	}
}
