.part-list {
	&__header {
		display: grid;
		grid-template-columns: 1.3em minmax(7em, 3fr) minmax(0, 10fr) minmax(80px, 2fr) 33px minmax(290px, 10fr);
		grid-template-areas: 'position partNumber information price quantity shoppingDetails';
		gap: 3em;
	}
}

.related-parts {
	.swipe-content__item {
		display: none;
	}

	.active-item {
		display: block;
	}
}
