@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

.webparts-item {
	display: grid;
	grid-template-columns: 1fr 7fr 1fr 3fr 0.75fr 1.5fr;
	grid-auto-flow: column;

	&__details {
		display: grid;
		grid-template-columns: 3fr 1fr 3fr;
	}

	&__price {
		min-width: 250px;

		&-item {
			display: flex;
			justify-content: space-between;
			grid-auto-flow: column;
			gap: $grid-spacing-small;
		}
	}
}

.item-header {
	display: grid;
	grid-template-columns: 1fr 7fr 1fr 5.25fr;
	grid-auto-flow: column;

	&__discount-price {
		display: grid;
		grid-template-columns: 3fr 1fr 3fr;
	}
}
