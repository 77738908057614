@import '~base/scss/_vars.scss';

.communication-event {
	&__video-section {
		transition: all 0.15s ease-in;

		&__poster {
			height: inherit;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 1fr 0.1fr;
			grid-gap: 20px;

			&-info {
				grid-column: 1 / span 1;
				grid-row: 1 / span 1;
				line-height: normal;
			}

			&-play {
				grid-column: 2 / span 1;
				grid-row: 1 / span 1;
				background-color: $azure;
				border-radius: 50%;
				border: solid 4px rgba(255, 255, 255, 0.2);
				width: fit-content;
				justify-self: center;

				&:hover {
					background-color: $cerulean;
				}
			}

			&-show-more {
				grid-column: 2 / span 1;
				grid-row: 2 / span 1;
				position: fixed;
				bottom: 40px;
				justify-self: center;
			}
		}

		&__chapter {
			.description {
				&:hover {
					color: $azure;

					span {
						color: $azure;
					}
				}
			}

			.progress-bar {
				width: 100%;
				min-width: 150px;
				background-color: $silver;
				height: 6px;
				border-radius: 17px;

				&__progression {
					width: 0%;
					background-color: $azure;
					height: 6px;
					border-radius: 17px;
					transition: width 250ms ease-in-out;
				}
			}
		}
	}

	&__video-panel {
		&__show-more {
			.scroll-sign {
				border-radius: 50%;
				position: absolute;
				bottom: 1.5vh;
				left: 50vw;
			}

			.text {
				position: absolute;
				bottom: 1.5vh;
				left: 51.5vw;
			}
		}
	}
}
