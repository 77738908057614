@import '~base/scss/_breakpoints.scss';

.rich-text-section {
	width: 66.6%;

	@include xs {
		width: 100%;
	}

	@include xxs {
		width: 100%;
	}
}
