@import '~base/scss/vars';

.export {
	&__menu {
		top: 0;
		right: 0;
		width: auto;

		&[data-align-left='true'] {
			left: 0;
			right: unset;
		}

		&__option {
			background-color: $white;

			&-text {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&:hover {
				background-color: $white-smoke;
				color: $azure;
			}

			&:not(:last-child) {
				border-bottom: 1px solid $very-light-pink-3;
			}
		}
	}
}
