@import '~base/scss/_vars.scss';

.part-subgroup-list {
	min-width: 300px;

	&__item {
		&-data {
			transition: all 0.15s ease-in;

			&:hover {
				cursor: pointer;
				background-color: $very-light-pink-1;
			}
		}

		&-images {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));

			> div {
				img {
					border: 2px solid transparent;
				}

				&:hover {
					border-color: $azure;
				}
			}
		}
	}

	.part-availability {
		align-items: start;
		grid-row: 1 / span 1;
		grid-column: 2 / span 1;
	}
}
