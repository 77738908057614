@import '~base/scss/vars';

$product-item-height: 76px;

.catalogue-error {
	align-items: center;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	width: 100%;
	background-color: $white;
}

.equipment-catalogue {
	&__header,
	&__error,
	.equipment-item {
		display: grid;
		gap: $grid-spacing-medium;
		grid-auto-flow: column;
		grid-template-columns: minmax(50px, 1.5fr) 3fr 3fr 4fr 2fr minmax(80px, 1fr) 0.5fr;
	}

	.error-message {
		display: grid;
		grid-column: span 6;
		grid-auto-flow: column;
		align-items: center;
		justify-content: flex-end;
	}

	&__header {
		&__price {
			text-align: right;
		}
	}

	&__list {
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);

		.equipment-item {
			height: $product-item-height;

			&__image {
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;

				img {
					height: max-content;
					width: max-content;
					max-height: 100%;
					max-width: 100%;
				}
			}

			&__name {
				&[data-public='false']:hover {
					color: $azure;
					cursor: pointer;
				}

				&--tooltip {
					display: unset;
				}
			}
		}
	}
}
