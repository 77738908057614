@import '~base/scss/_vars.scss';

.wi-dropdown {
	grid-column: 1 / span 2;
}

.wi-address-field {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 150px;
	gap: $grid-spacing-medium;
}

.wi-address-field--loading {
	grid-column: 1 / span 2;
	display: flex;
	justify-content: center;
}

.wi-address-fit-line {
	white-space: nowrap;
}
