@import '~base/scss/vars';

$description-max-width: 500px;
$grid-spacing-xsmall: 5px;
$grid-spacing-medium: calc(#{$grid-spacing-small} * 2);
$arrow-width: 54px;

@mixin grid-centered {
	display: grid;
	align-items: center;
}

@mixin opening-hours-item-wrapper {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr $arrow-width;
}

@mixin opening-hours-item-content-wrapper {
	grid-template-columns: 6.5fr 6.5fr 8fr;
}

.opening-hours {
	@include grid-centered;
	@include opening-hours-item-content-wrapper;

	grid-auto-flow: column;
	gap: $grid-spacing-small;

	&__item {
		&.odd {
			background-color: $white;
		}

		&.even {
			background-color: $white-smoke;
		}
	}

	&--collapsible {
		.accordion-item__title {
			@include opening-hours-item-wrapper;

			.opening-hours {
				@include opening-hours-item-content-wrapper;
			}
		}
	}

	&__table {
		border-collapse: collapse;
		width: 100%;

		td {
			border: 1px solid $very-light-pink-3;
		}
	}
}
