@import '~base/scss/vars';

.webparts-tooltip {
	&--dark {
		max-width: 300px;
		text-align: left;
		border-radius: 4px !important;
		font-family: DaimlerCS-Regular;
		opacity: 1 !important;
	}

	&--light {
		max-width: 300px;
		text-align: left;
		border-radius: 4px !important;
		font-family: DaimlerCS-Demi;
		opacity: 2 !important;

		.link {
			display: inline;
			text-decoration: underline;
			color: $azure;

			&:hover {
				color: $cerulean;

				span {
					color: $cerulean;
				}
			}
		}
	}
}
