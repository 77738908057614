@import '~base/scss/_vars.scss';

.main-group-list {
	height: 625px;

	&__container {
		transition: width 1s ease-in-out;

		&-expanded {
			width: 300px;
		}

		&-collapsed {
			width: 50px;
		}
	}

	&__collapse-arrow {
		transition: transform 1s ease-in-out;

		&-collapsed {
			transform: rotate(180deg);
		}
	}

	&__item {
		white-space: nowrap;
		overflow: hidden;

		&-data {
			transition: all 0.15s ease-in;

			&:hover {
				cursor: pointer;
				background-color: $very-light-pink-1;
			}
		}
	}

	&__search {
		width: auto !important;
	}

	.part-availability {
		align-items: start;
		grid-row: 1 / span 1;
		grid-column: 2 / span 1;
	}
}
