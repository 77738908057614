@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';
$header-height: 76px;
$scrollbar-width: 4px;
$gradient-height: 50px;

:root {
	--mega-menu-top-position: 0;
}

.flyout-menu {
	background-color: $white;
	margin: 20px 0 20px 20px;
	z-index: 3;
	display: flex;
	flex-direction: column;
	width: calc(28.5vw - 20px);
	height: calc(100vh - 40px - var(--mega-menu-top-position));
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 4px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	animation: left-to-right-menu 0.2s;

	@include lt-sm {
		width: 502px;
		height: calc(100vh - 40px - var(--mega-menu-top-position));
	}

	@include lt-xxs {
		top: 0;
		height: calc(var(--app-height) - var(--mega-menu-top-position));
		margin: 0;
		width: 100vw;
		border-radius: unset;
	}

	@include gt-uhd {
		width: calc(21.5vw - 20px);
	}

	&--closing {
		animation: right-to-left-menu 0.3s;
	}

	&__content {
		width: inherit;
		overflow-y: auto;
		overflow-x: hidden;
		height: 100%;

		@include lt-xs {
			padding-bottom: 50px;
		}

		&__gradient {
			transition: all 500ms ease-in-out;
			pointer-events: none;
			position: absolute;
		}

		&--gradient-bottom {
			display: none;
			position: fixed;
			bottom: calc(var(--mega-menu-top-position) + 20px);
			height: $gradient-height;
			width: inherit;
			background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $white);
		}

		&--gradient-top {
			display: none;
			position: fixed;
			height: $gradient-height;
			width: inherit;

			&.top {
				background-image: linear-gradient(to top, rgba(255, 255, 255, 0), $white);
			}

			&.bottom {
				background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $white);
				bottom: 0;
			}

			&.hidden {
				opacity: 0;
			}
		}
	}

	&__header {
		margin-top: 56px;
		display: flex;
		align-items: center;
		min-height: 60px;
		height: auto;
		font-size: 38px;

		@include lt-xxs {
			&[data-responsive-item-available='false'] {
				display: none;
			}

			margin-top: 20px;
		}

		&--inner-level {
			font-size: 22px;
			padding: 10px 40px 10px 20px;
		}

		&__back-button {
			visibility: hidden;

			&:hover {
				cursor: pointer;
			}

			@include lt-sm {
				visibility: visible;
				display: inline-grid;
				align-content: center;
			}
		}
	}

	&__close-button {
		right: 0;
		top: 0;
		margin: 20px;
		display: flex;

		&:hover {
			cursor: pointer;
		}

		@include lt-xxs {
			display: none;
		}
	}

	&__second-level {
		width: calc((100vw - 28.5vw - 40px) / 2);
		left: calc(28.5vw);
		position: absolute;
		margin: 20px 0 20px 10px;

		@include gt-uhd {
			width: calc((100vw - 49.5vw - 40px) / 2);
			left: calc(21.5vw);
		}

		@include lt-sm {
			left: 0;
			width: 502px;
			margin: 20px 0 20px 20px;
		}

		@include lt-xxs {
			margin: 0;
			width: 100vw;
		}
	}

	&__third-level {
		width: calc((100vw - 28.5vw - 70px) / 2);
		left: calc(28.5vw + (100vw - 28.5vw - 20px) / 2);
		position: absolute;
		margin: 20px 20px 20px 10px;

		@include gt-uhd {
			width: calc((100vw - 49.5vw - 70px) / 2);
			left: calc(21.5vw + (100vw - 49.5vw - 20px) / 2);
		}

		@include lt-sm {
			left: 0;
			width: 502px;
			margin: 20px 0 20px 20px;
		}

		@include lt-xxs {
			margin: 0;
			width: 100vw;
		}
	}

	&__link {
		display: none;
		padding: 30px 40px 10px 20px;

		&[data-show-always='true'] {
			display: block;
		}

		@include lt-xs {
			display: block;
		}
	}
}

.flyout-menu-mask {
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.6);
	height: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	transition: opacity 0.3s ease-in-out;

	&--closing {
		opacity: 0;
	}

	@include lt-sm {
		right: 0;
	}
}

.menu-container {
	position: fixed;
	width: 100vw;
	height: calc(100vh - var(--mega-menu-top-position));
	display: flex;
	align-items: center;
	justify-content: center;
	top: var(--mega-menu-top-position);
	left: 0;
	z-index: 9999;
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
}

@keyframes left-to-right-menu {
	from {
		transform: translateX(-40px);
		opacity: 0;
	}

	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes right-to-left-menu {
	from {
		transform: translateX(0);
	}

	to {
		opacity: 0;
		transform: translateX(-40px);
	}
}
