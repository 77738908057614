@import '~base/scss/_vars.scss';

.time-credits-container {
	width: 100%;

	&__row {
		display: grid;
		gap: 30px;
		align-items: center;
		line-height: 44px;
		grid-template-columns: minmax(max-content, 100px) minmax(min-content, 80px) minmax(auto, 50px) auto;

		&:last-child {
			border: none;
		}

		&__icon {
			transform: scaleX(-1);
		}
	}

	.no-item {
		width: 100%;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
}
