@import '~base/scss/vars';

.order-details-modal {
	font-size: 14px;

	td {
		padding: 0 10px 10px 0;
	}

	&__content {
		&-name {
			max-width: 25vw;
			word-break: break-word;
		}
	}

	&__summary-box {
		background-color: $white-smoke;
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);

		&-item {
			justify-content: space-between;
		}
	}
}
