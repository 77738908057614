@import '~base/scss/_breakpoints.scss';
@import '~base/scss/_vars.scss';

.part-packages {
	@include sm {
		padding-left: 65px;
	}

	&__section {
		background-color: $white-smoke;

		&__add-all {
			&--disabled {
				pointer-events: none;
				opacity: 0.4;
			}

			&:hover {
				color: $azure;
				cursor: pointer;

				span {
					color: $azure;
				}
			}
		}

		&:nth-child(odd) {
			background-color: $white;
		}

		&-line-spacing {
			height: 2em;
		}

		&-item {
			height: 4em;
			vertical-align: top;

			&__shopping-details {
				justify-content: flex-end;
				align-items: flex-start;
				gap: 1em;
			}
		}

		table {
			border-collapse: collapse;

			th {
				font-weight: normal;
			}
		}
	}
}
