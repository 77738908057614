@import '~base/scss/_vars.scss';

.order-rating {
	&__questions {
		display: grid;
		gap: $grid-spacing-large;
	}

	&__stars {
		gap: $grid-spacing-small;
		margin-left: auto;
	}
}

.text-area__wrapper {
	margin-top: $grid-spacing-large;
}

.modal__content {
	margin: 0;
}
