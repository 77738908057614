@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

$platform-search-result-width: 776px;
$input-animation-time: 0.25s;
$input-height: 40px;

.header-platform-search {
	width: 180px;
	position: relative;
	height: $input-height;

	@include lt-md {
		width: 40px;
	}

	&[data-hidden="true"] {
		visibility: hidden;
	}

	&__active-search-bar {
		position: absolute;
		width: 180px;
		transition: all $input-animation-time linear;
		background-color: $black-five;
		height: $input-height;

		@include lt-md {
			width: 40px;
		}

		@include lt-md {
			cursor: pointer;
			justify-content: center;

			input {
				width: 0;

				&::placeholder {
					color: transparent;
				}
			}
		}

		&[data-active='true'] {
			width: 700px;
			z-index: 99;
			animation: input-bg-color-change $input-animation-time linear;
			background-color: $white;

			input {
				width: 100%;

				&::placeholder {
					color: $brownish-grey;
				}
			}

			@include lt-sm {
				width: 70vw;
			}

			@include lt-xs {
				position: absolute;
				right: -100px;
			}
		}
	}

	&__result {
		visibility: hidden;

		&--active {
			width: inherit;
			left: 0;
			top: calc(#{$input-height} + 5px);
			padding: 20px 15px 15px;
			animation: 0.4s fade-in-search;
			animation-fill-mode: forwards;
			animation-delay: $input-animation-time;
		}
	}

	&__input {
		&::placeholder {
			color: $very-light-pink-3;
			opacity: 1;
			border: none !important;
		}

		&:focus { outline: none; }
	}

	&__overlay {
		flex-direction: column;
		align-items: center;
		gap: 5px;
		right: 0;
		bottom: 0;
		z-index: 98;
		display: none;
		background-color: rgba(0, 0, 0, 0);

		&--active {
			display: flex;
			animation: bg-animation 0.2s linear;
			background-color: rgba(0, 0, 0, 0.6);
		}
	}
}

@keyframes bg-animation {
	from { background-color: rgba(0, 0, 0, 0); }
	to { background-color: rgba(0, 0, 0, 0.6); }
}

@keyframes input-bg-color-change {
	from { background-color: $black-five; }
	to { background-color: $white; }
}

@keyframes result-bg-color-change {
	from { background-color: rgba(0, 0, 0, 0); }
	to { background-color: $white; }
}

@keyframes fade-in-search {
	0% {
		opacity: 0;
	}

	100% {
		visibility: visible;
		opacity: 1;
	}
}
