@import '~base/scss/_vars.scss';

$animation-time: 0.1s;

.product-details {
	&-container {
		flex-wrap: nowrap;
		display: grid;
		grid-template-areas:
			'CLOSE-AREA CLOSE-AREA'
			'PRODUCT-CONTENT PRODUCT-IMAGE';
		grid-template-rows: 66px minmax(0, 1fr);
		grid-template-columns: 2fr 3fr;
		margin-top: 60px;

		&__close {
			grid-area: CLOSE-AREA;

			&__label {
				position: absolute;
				height: 20px;
				top: -5px;
				left: 60px;
				background: $azure;
				color: $white-two;
				font-family: 'DaimlerCS-Demi';
				font-size: 12px;
				text-align: center;
				padding: 3px 6px;
				line-height: normal;
			}

			&__wrapper {
				width: 100%;
				background: $white;
				height: 66px;
				border-bottom: 1px solid $asphalt;
			}

			&__icon {
				position: absolute;
				top: 16px;
				right: 16px;
			}
		}

		&__content {
			grid-area: PRODUCT-CONTENT;
		}

		&__image {
			grid-area: PRODUCT-IMAGE;
		}
	}

	&-slider {
		&__modal {
			.modal__plain {
				height: 100%;
				width: 100%;
				max-width: 100%;
			}

			.slick-slider,
			.slick-list,
			.slick-track,
			.slick-slide > div {
				height: 100%;
			}

			.slick-track {
				position: relative;
			}

			.slick-slider {
				padding: 25px 0 25px 0;

				.product-details__next,
				.product-details__prev {
					z-index: 1;
					position: absolute;
					height: calc(100% - 50px);
					margin-top: 25px;
					width: 40px;
					background-color: rgba($white, 0.4);
					color: $white;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					transition:
						margin $animation-time,
						width $animation-time,
						background-color $animation-time,
						opacity $animation-time;

					&[data-hidden='true'] {
						opacity: 0;
						visibility: hidden;
					}

					.icon {
						transition: width $animation-time, height $animation-time;
					}

					&:hover {
						width: 60px;
						background-color: $white;
						color: $azure;

						.icon {
							width: 44px;
							height: 44px;
						}
					}
				}

				.product-details__next {
					margin-left: 20px;
					right: 0;
					top: 0;

					&:hover {
						margin-left: 0;
					}
				}

				.product-details__prev {
					margin-right: 20px;
					left: 0;
					top: 0;

					&:hover {
						margin-right: 0;
					}
				}
			}

			.slick-slide > div {
				display: flex;
				justify-content: center;
			}

			.slick-active {
				.product-details__next,
				.product-details__prev {
					opacity: 0;
					transition: none;
					pointer-events: none;
				}
			}
		}

		&__view-360 {
			&:hover {
				color: $cerulean;
				transition: $animation-time;
			}
		}

		&__product {
			&__content {
				overflow: auto;

				.product-gallery__currentImage {
					display: flex;

					img {
						aspect-ratio: 16 / 9;
					}
				}
			}

			&__title {
				word-break: keep-all;
			}
		}
	}

	&__login-buttons {
		align-self: flex-end;

		&__container {
			justify-content: flex-end;
		}
	}
}
