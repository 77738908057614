@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

.quickstart {
	&[data-hidden='true'] {
		display: none;
	}

	&__tiles {
		gap: 20px;
		grid-template-columns: repeat(3, 1fr);

		@include lt-sm {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}

.application-item {
	height: 103px;
	border-radius: 4px;

	&:hover {
		border-color: $azure;
	}
}

.dwd-tooltip {
	z-index: 1000 !important;
	opacity: 1 !important;
}
