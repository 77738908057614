@import '~base/scss/_vars.scss';

.breadcrumb {
	&--dark {
		.breadcrumb__item {
			&::after {
				color: $black-two;
			}
		}
	}

	&--light {
		.breadcrumb__item {
			&::after {
				color: $white;
			}
		}
	}

	&__item {
		&--forward-slash {
			&:not(:last-child)::after {
				content: '/';
				padding: 0 5px;
			}
		}

		&--chevron {
			&:not(:last-child)::after {
				content: '';
				padding: 0 5px;
			}
		}

		&--disabled {
			pointer-events: none;
		}
	}
}
