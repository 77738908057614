@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

%order-row {
	display: grid;
	column-gap: 40px;
	height: 64px;
	padding-left: 10px;
	border-radius: 4px;
	align-items: center;
	grid-template-columns: minmax(55px, 0.6fr) minmax(60px, 0.6fr) minmax(50px, 140px) repeat(4, minmax(80px, 1.2fr));
	grid-auto-flow: column;

	@include lt-md {
		column-gap: 15px;
	}
}

.orders-wrapper {
	width: 100%;

	.orders-header {
		margin-bottom: -10px;

		@extend %order-row;
	}

	.orders-item {
		@extend %order-row;

		&:nth-child(even) {
			background-color: $white-smoke;
		}

		&:hover {
			&[data-hover='true'] {
				cursor: pointer;
			}

			outline: 1px solid $very-light-pink-3;

			.order-details-column {
				color: $azure;
			}
		}

		&__error {
			display: flex;
			grid-column: 1 / -1;
			padding: 10px;
			gap: 0;
		}
	}
}
