@import '~base/scss/vars';

$padding: 10px 20px;

.completed-services {
	&__bottom {
		justify-content: flex-end;
		display: flex;
	}

	&__title,
	&__service-item {
		display: grid;
		gap: $grid-spacing-small;
		grid-auto-flow: column;
		grid-template-columns: minmax(80px, 1.5fr) 1.5fr 1.5fr 5fr 4fr;
		align-items: center;
	}

	&__title {
		padding: $padding;
	}

	&__no-entry {
		background-color: $white-smoke;
		padding: $padding;
	}

	&__service-item {
		position: relative;
		color: $black-two;
		padding-left: 20px;
		min-height: 34px;
	}

	&__service-item:nth-child(2n-1) {
		background-color: $white-smoke;
	}

	&__service-item:nth-child(2n) {
		background-color: $white;
	}

	&__successfully-saved {
		&--container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 1;
			background: #fff;
			text-align: center;
			visibility: visible;
		}

		&--hidden {
			opacity: 0;
			transition: visibility 0s 1s, opacity 1s linear;
			visibility: hidden;
		}
	}

	&__success-text {
		height: 100%;
		padding-left: 10px;
	}
}

.export-pdf-session {
	&__selection {
		display: flex;
		justify-content: flex-end;
	}
}

.next-service {
	&__info {
		grid-template-columns: 4fr 5fr 3.5fr;
		gap: 30px;
		padding-left: 20px;
		color: $black-two;
		min-height: 46px;
	}
}

.service-filter {
	&__year-select {
		min-width: 180px;
		z-index: 1;

		.dropdown-checklist__select,
		.dropdown-checklist__items {
			background-color: $white-smoke;
		}
	}

	&__type-select {
		gap: 5px;
	}
}
