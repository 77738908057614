@import '~base/scss/vars';

.webpart-products {
	&-container {
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	}

	&-header {
		border-radius: 4px;
		background-color: $white-two;
		display: flex;
		align-content: center;
		flex-direction: row;
	}
}
