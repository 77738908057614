.add-via-modal-part-header {
	display: grid;
	grid-template-columns: minmax(7em, 3fr) minmax(0, 10fr) minmax(80px, 2fr) 33px minmax(290px, 10fr);
	grid-template-areas: 'partNumber information price quantity';
	gap: 3em;

	.price {
		grid-area: price;
	}

	.partNumber {
		grid-area: partNumber;
	}

	.information {
		grid-area: information;
	}

	.quantity {
		grid-area: quantity;
	}
}
