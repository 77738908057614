@import '~base/scss/_breakpoints.scss';

$space-between-category-items: 30px;

.reman-vehicle {
	&__header {
		grid-auto-flow: column;
		grid-template-columns: 3fr 2fr;

		&__search {
			margin-left: auto;
		}
	}

	&__category-list {
		gap: $space-between-category-items;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		grid-column: 1 / 5;
	}
}
