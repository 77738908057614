@import '~base/scss/_vars.scss';

$error-icon-padding: 5px;

.licenses {
	&__loading {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		text-align: center;
		min-height: 250px;
	}

	&__error {
		&__icon > svg {
			padding: $error-icon-padding;
		}
	}

	&__item {
		position: relative;
		min-height: 64px;
		padding-left: 7px;

		section {
			width: 100%;
		}

		.accordion-item__arrow {
			position: absolute;
			right: 0;
		}

		.pass-thru-license {
			width: 100%;
			display: grid;
			grid-gap: 10px;
			grid-auto-flow: column;
			align-items: center;
			grid-template-columns: 1fr 1.2fr;
		}
	}

	&__line {
		&:nth-child(odd) {
			background-color: $white-smoke;
		}
	}
}
