@import '~base/scss/_breakpoints.scss';

.chips {
	&__chip-dropdown {
		display: none;

		@include lt-sm {
			display: flex;
			width: 280px;
		}
	}

	&__chip-list {
		display: flex;
		flex-wrap: wrap;
		gap: 5px;

		@include lt-sm {
			display: none;
		}
	}
}
