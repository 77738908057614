/* stylelint-disable declaration-colon-newline-after */
@import '~base/scss/_breakpoints.scss';
@import '~base/scss/vars';

$quote-module-padding: 40px;

.home {
	&__modules {
		max-width: 1220px;
		width: 100vw;

		@extend %module-animation;

		&__glance {
			flex-wrap: wrap;
			grid-template-rows: 40px 40px 1fr;

			&.animation {
				@for $i from 1 through 8 {
					.home__modules__glance__box:nth-child(#{$i}) {
						opacity: 0;
						animation: fade-in 1s calc(#{$i}s * 0.15) forwards;
					}
				}
			}

			&__box {
				flex: 1;
				grid-template-rows: 64px 50px auto;
				padding: 20px;

				@include lt-sm {
					flex-basis: 20%;
				}

				@include lt-xs {
					flex-basis: 40%;
				}

				@include lt-xxs {
					text-align: center;
					flex-basis: 100%;
				}
			}
		}

		&__benefits {
			background-image: linear-gradient(168deg, #f7f7f7 12%, #f1f1f1 90%);

			@include lt-xxs {
				background-image: none;
				padding: 0;
			}

			&__content {
				grid-template-columns: 1fr 1fr;
				gap: 40px;

				@include lt-xxs {
					grid-template-columns: 1fr;
					gap: 0;
				}
			}

			img {
				max-height: 342px;
				max-width: 478px;
			}
		}

		&__platform-preview {
			width: 100%;

			@extend %override-tabs;

			.platform-preview__media {
				height: fit-content;

				img,
				video {
					max-width: 1115px;
					border-radius: 8px;
					height: 100%;
					width: 100%;

					@include lt-lg {
						max-width: 1000px;
					}

					@include lt-md {
						max-width: 850px;
					}

					@include lt-sm {
						max-width: 730px;
					}

					@include lt-xs {
						max-width: 480px;
					}

					@include lt-xxs {
						max-width: 345px;
						border-radius: 4px;
					}
				}
			}
		}

		&__register {
			min-height: 400px;
			background-image: linear-gradient(rgba($white, 0.8), 30%, rgba($white-smoke, 0.2)),
				url('@Static/images/icon-grid.svg');
			background-size: 600px;
			max-width: unset;
			grid-template-rows: 2fr 1fr;
			padding: 45px;

			&__buttons {
				width: 100%;
				display: flex;
				justify-content: center;
				gap: 10px;

				@include lt-xxs {
					flex-direction: column-reverse;
				}
			}

			@include lt-xs {
				padding: 22.5px;
			}
		}

		&__quote::before {
			background-image: linear-gradient(153deg, #f7f7f7 29%, #f1f1f1 74%);
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			@include lt-xxs {
				clip-path: polygon(0 90px, 100% 90px, 100% 100%, 0% 100%);
			}
		}

		&__quote {
			padding: 40px;
			width: 100%;
			height: auto;
			position: relative;

			&__item {
				grid-template-columns: 1fr 2fr;
				gap: 40px;

				&[data-no-media='true'] {
					grid-template-columns: 1fr;
				}

				@include lt-xxs {
					display: block;
				}

				&-mobile-content {
					display: none;

					@include lt-xxs {
						display: inherit;
					}
				}

				&-content {
					display: inherit;

					@include lt-xxs {
						display: none;
					}
				}

				&-image {
					display: grid;
					justify-items: center;
					align-items: center;
				}

				&-chip {
					min-width: min-content;
					max-width: 300px;
					transform: translateY(-50%);
					position: relative;
					white-space: normal;
					text-align: center;
					height: min-content;
				}

				img {
					width: 172px;
					height: 172px;
					border-radius: 100%;
					object-fit: cover;
				}

				blockquote::before {
					content: open-quote;
					position: relative;
					top: 40px;
				}

				cite {
					position: absolute;
					right: 0;
					bottom: 0;
					text-align: right;
					font-style: normal;
					font-size: 18px;

					@include lt-xxs {
						bottom: 0;
					}
				}

				blockquote::after {
					content: close-quote;
				}

				blockquote {
					quotes: '”' '“';
					font-size: 80px;
					line-height: 1;
					position: relative;
				}

				&-cms-content {
					p {
						font-family: 'DaimlerCAC-Regular';
						margin: 0;
						font-size: 40px;
					}
				}
			}

			.slick-dots li {
				width: 10px;
			}

			.slick-arrow {
				margin-right: 20px;
			}

			.slick-prev::before,
			.slick-next::before {
				content: '';
			}

			.slick-slider {
				display: grid;
			}

			.pager__item {
				height: 20px;
				position: relative;

				span {
					display: block;
					height: 5px;
					width: 5px;
					background: $silver;
					position: absolute;
					top: calc(50% - 1px);
					left: 0;
					border-radius: 50%;
				}
			}

			li.slick-active {
				div {
					span {
						background-color: $azure;
					}
				}
			}

			.slick-track {
				position: relative;
				display: flex;
				align-items: center;
			}
		}
	}
}

.video-module {
	margin-top: -200px;
	max-width: 1220px;
	width: 100vw;
	padding: 20px;
	justify-self: center;
	z-index: 2;
	background-color: $white;

	@include lt-sm {
		margin-top: -170px;
		width: 90vw;
	}

	@include lt-md {
		margin-top: -160px;
		width: 80vw;
	}

	@include lt-lg {
		margin-top: -160px;
		width: 80vw;
	}

	@include gt-hd {
		margin-top: -350px;
		padding: 40px;
	}

	&__play {
		background-color: $azure;
		border-radius: 50%;

		&:hover {
			background-color: $cerulean;
		}
	}

	&__image {
		img {
			object-fit: contain;
		}
	}

	&__subtext {
		justify-self: center;
	}
}

%override-tabs {
	.tabs__tab-list__wrapper {
		justify-content: center;
	}

	.tabs__tab-list {
		width: auto;
	}
}

%module-animation {
	&.animation {
		opacity: 0;
		animation: fade-in 1.2s ease-out forwards;
	}
}

@keyframes fade-in {
	0% {
		transform: translateY(70px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
