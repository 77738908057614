@import '~base/scss/_vars.scss';

.shopping-card__tooltip {
	z-index: 9999 !important;
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;

	&--content {
		position: relative !important;
	}
}

.vehicle-type {
	&__all-divisions {
		align-items: flex-start;
	}
}
