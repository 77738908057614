@import '~base/scss/_vars.scss';

.vehicle-tabs {
	&__content {
		overflow: visible !important;
	}

	.old-vehicle {
		font-weight: normal;

		&__dropdown {
			width: 410px;
		}
	}
}
