.asp-start {
	display: flex;
	justify-content: center;

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 600px;
	}
}
