@import '~base/scss/_vars.scss';

.webparts-checkout {
	.back-button {
		cursor: pointer;
		width: max-content;
	}

	&__content {
		display: grid;
		gap: calc(2 * #{$grid-spacing-large});
		grid-template-columns: 1fr 1.5fr;
		grid-auto-flow: column;
		align-items: start;

		.billing-address--error,
		.billing-address--loading,
		.payment--error {
			height: 250px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.billing-address,
		.payment {
			box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
			background-color: $white;
		}

		.payment {
			&__button {
				span {
					margin-right: $grid-spacing-small;
				}
			}
		}

		.delivery-type {
			align-items: center !important;
		}
	}

	&__footer {
		display: grid;
		gap: $grid-spacing-small;
		grid-auto-flow: column;
		grid-template-columns: 1fr 1fr;
	}
}
