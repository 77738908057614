@import '~base/scss/_vars.scss';

.company {
	&__container {
		&__item {
			grid-template-columns: minmax(175px, 1fr) 3fr;
			align-content: center;

			&:nth-child(odd) {
				background-color: $white-smoke;
			}
		}

		&__settings-item {
			grid-template-columns: minmax(175px, 4fr) 1fr;
			align-content: center;

			&:nth-child(even) {
				background-color: $white-smoke;
			}

			&__toggle {
				width: 45px;
				height: 20px;
				z-index: 5;
			}
		}
	}
}
