.special-modification-notice {
	display: flex;
	align-items: center;
	min-height: 46px;
	padding: 8px 10px;

	&__wrapper {
		display: flex;
		font-weight: normal;
	}

	.icon {
		margin-right: 5px;
	}
}
