@import '~base/scss/_breakpoints.scss';

$space-between-sections: 80px;
$space-in-middle-of-sections: 40px;
$space-between-product-items: 30px;
$box-shadow-value: 0 1px 4px 0 rgba(0, 0, 0, 0.2);

@mixin grid-row {
	@media only screen and (max-width: 650px) {
		display: grid;
		grid-template-columns: auto;
		grid-column: auto;
		grid-auto-flow: row;
	}
}

@mixin grid-row-wider {
	@media only screen and (max-width: 750px) {
		grid-template-columns: 30% 30% 30%;
		grid-column: auto;
		grid-auto-flow: row;
	}
}

@mixin flex-justify-center-wider {
	@media only screen and (min-width: 750px) {
		display: flex;
		justify-content: center;
		gap: 40px;
	}
}

@mixin grid-row-smaller {
	@media only screen and (max-width: 450px) {
		grid-template-columns: 1fr;
		grid-column: auto;
		grid-auto-flow: row;
	}
}

@mixin image-cover {
	aspect-ratio: 16 / 9;
	object-fit: cover;
}

.star-parts {
	display: grid;
	gap: $space-between-sections;

	&__faq {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		box-shadow: $box-shadow-value;

		@include grid-row;

		img {
			@include image-cover;

			max-height: 300px;
		}
	}

	.orders {
		display: grid;
		gap: $space-in-middle-of-sections;
		grid-template-columns: repeat(2, 1fr);

		@include grid-row;
	}

	.products {
		&__product-list {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-column: 1 / 5;
			gap: $space-between-product-items;

			@include grid-row-wider;
			@include grid-row-smaller;
		}
	}

	.comparison {
		&__bullet-list {
			display: block;

			@include flex-justify-center-wider;
		}

		&__wrapper {
			flex-wrap: wrap;
			gap: 30px;
		}

		&__box {
			min-width: 280px;
			max-width: 320px;
			flex-basis: 280px;
			flex-shrink: 1;
			flex-grow: 1;
			box-shadow: $box-shadow-value;
			list-style-type: none;

			@include xxs {
				flex-grow: 0;
			}

			img {
				max-width: 95px;
				max-height: 65px;
				object-fit: scale-down;
			}
		}
	}
}
