@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

$news-section-height: 700px;
$new-detail-max-width: 700px;

.news {
	grid-template-columns: minmax(300px, 1fr) 2fr;
	height: $news-section-height;

	img {
		width: auto;
	}

	&-list,
	&-detail {
		display: flex;
		flex-direction: column;
		flex: 1;
		min-height: 700px;
		overflow-y: scroll;
		scroll-snap-align: start;

		&__container {
			overflow-y: auto;
			transform: all 100ms ease;

			:first-child {
				&.news__separator {
					display: none;
				}
			}

			@extend %hide-scrollbar;
		}

		@extend %hide-scrollbar;
	}

	&__feedback {
		margin-top: auto;
	}

	&__separator {
		margin: 50px auto;
		max-width: 700px;
		align-self: flex-end;
	}

	&-list {
		&__year-group {
			padding-left: 20px;
		}
	}

	&-detail {
		padding: 40px max(40px, calc((100% - $new-detail-max-width) / 2));
		justify-self: center;

		@include lt-sm {
			max-width: -webkit-fill-available;
		}
	}

	.news-feed {
		position: relative;
		padding: 10px 20px 20px 20px;

		&--selected {
			background-color: $white;

			@extend %bg-animation;
			@extend %triangle-indicator;
		}

		&__title {
			position: relative;

			&::before {
				position: absolute;
				border: 3px solid $blood-red;
				border-radius: 3px;
				top: 0.6rem;
				left: -6px;
				content: '';
				transition: all 150ms ease-in-out;
				opacity: 0;
			}

			&[data-unread='true'] {
				&::before {
					left: -12px;
					opacity: 1;
				}
			}
		}
	}
}

%hide-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

%triangle-indicator {
	&::after {
		content: '';
		position: absolute;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-right: 10px solid transparent;
		border-left: 10px solid $white;
		right: -20px;
		top: calc(50% - 10px);
	}
}

%bg-animation {
	-webkit-transition: background-color 300ms linear;
	-ms-transition: background-color 300ms linear;
	transition: background-color 300ms linear;
}
