.sadcar-error {
	min-height: 200px;
	gap: 30px;

	&.row {
		flex-direction: row;
	}

	&.column {
		flex-direction: column;
		gap: 10px;
		text-align: justify;
	}

	&__icon {
		height: 80px;
		width: 120px;
	}

	&__text {
		max-width: 420px;
		width: 70%;

		&.full-width {
			width: 100%;
		}
	}
}
