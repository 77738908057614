.webparts-bulk-order {
	width: 1050px;
	height: 600px;

	.button--secondary {
		visibility: hidden;
	}

	.button--primary {
		font-size: 16px;
	}

	th {
		font-weight: normal;
	}

	&-row {
		text-overflow: ellipsis;
		overflow: hidden;
		height: 40px;
	}
}
