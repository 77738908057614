@import '~base/scss/_breakpoints.scss';
@import '~base/scss/vars';

$section-top-bottom-margin: 80px;
$section-top-bottom-margin-mobile: 60px;

$middle-gap: 40px;
$middle-gap-tablet: 24px;

@mixin gap {
	gap: $middle-gap;

	@include lt-sm {
		gap: $middle-gap-tablet;
	}

	@include xxs {
		gap: $middle-gap;
	}
}

@mixin grid {
	grid-template-columns: repeat(2, 1fr);

	@include xxs {
		grid-auto-flow: row;
		grid-template-columns: auto;
	}
}

.campaigns {
	.cms-content p {
		margin: 10px 0 0 0;
	}

	.welcome-box {
		margin-top: -40px;

		@include gap;
		@include grid;

		&__image {
			img {
				object-fit: contain;
			}

			@include xxs {
				grid-row: 2 / 3;
			}
		}
	}

	.campaign-content {
		section {
			@include gap;
			@include grid;

			margin: $section-top-bottom-margin 0 $section-top-bottom-margin;

			@include xxs {
				margin: $section-top-bottom-margin-mobile 0 $section-top-bottom-margin-mobile;
			}
		}

		&__image-wrapper {
			@include xxs {
				grid-row: 2 / 3;
				margin-left: -5%;
				width: 100vw;
				padding: 20px;
			}

			img {
				object-fit: contain;
			}
		}

		&__dot-list,
		&__icon-list {
			@include xxs {
				margin-left: -5%;
				width: 100vw;
				padding: 20px;
			}
		}

		&__dot-list__content {
			@include xxs {
				padding-left: 20px;
			}
		}

		&__column-module {
			justify-content: space-evenly;
			margin: $section-top-bottom-margin 0 $section-top-bottom-margin;

			@include xxs {
				display: grid;
				grid-auto-flow: row;
			}

			&__item {
				flex-direction: column;
				align-items: start;
				width: 30%;

				@include xxs {
					width: 100%;
					margin-bottom: 40px;
				}
			}

			&__image {
				img {
					object-fit: contain;
				}

				@include xxs {
					width: 80%;
				}
			}

			&__title,
			&__description {
				text-align: center;
				width: 100%;
				justify-content: center;

				@include xxs {
					text-align: left;
					width: 90%;
					justify-content: flex-start;
				}
			}
		}

		&__breaker-module {
			padding: 20px;
			display: grid;
			grid-template-columns: min-content auto;
			grid-template-areas:
				"image headline"
				"image description";

			@include xxs {
				margin-left: -5%;
				width: 100vw;
				display: grid;
				padding-bottom: 40px;
				padding-top: 40px;
				grid-template-columns: min-content auto;
				grid-template-areas:
					"image headline"
					"description description";
			}

			&__image {
				grid-area: image;
				width: 300px;
				padding-right: 20px;

				@include xxs {
					width: 130px;
					padding-right: 16px;
					margin-bottom: 15px;
				}
			}

			&__headline {
				grid-area: headline;
				display: flex;
				flex-wrap: wrap;
				align-content: center;

				@include xxs {
					padding-left: 0;
					padding-right: 0;
				}
			}

			&__description {
				grid-area: description;
				word-break: break-word;

				@include xxs {
					padding-left: 0;
					padding-right: 0;
				}

				:first-child:last-child {
					margin: 0;
				}
			}
		}
	}

	.campaign-footer {
		&__main-section {
			@include gap;
		}

		&__section {
			@include gap;
			@include grid;

			grid-template-columns: 1fr 1fr;

			&__section:has(.campaign-footer__section__section-card:first-child:last-child) {
				grid-template-columns: auto;
			}

			&__section-card:not(:only-child):first-child {
				border-right: solid 1px $very-dark-grey;

				@include xxs {
					border-bottom: solid 1px $very-dark-grey;
					border-right: none;
				}
			}
		}

		&__links {
			aside {
				a {
					text-decoration: none;

					&:not(:last-child)::after {
						content: '|';
						color: $white;
						padding: 0 10px;
					}
				}
			}
		}
	}

	.sticky-footer {
		@include gap;

		padding: 20px;

		@include xxs {
			height: 124px;
			padding: 12px;
		}

		&__wrapper {
			max-height: 300px;
			transition-property: max-height, padding;
			transition-duration: 0.2s;
			transition-timing-function: ease-in;

			&--hidden {
				max-height: 0;
				overflow: hidden;
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		&__content {
			@include xxs {
				flex-flow: column;
				align-items: center;
				justify-content: flex-start;

				button {
					width: 80%;
				}
			}
		}
	}
}
