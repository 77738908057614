$space-between-sections: 80px;

.service-detail {
	&__title {
		margin-bottom: $space-between-sections;
	}

	&__feature-wrapper {
		gap: $space-between-sections;
	}
}
