@import '~base/scss/_breakpoints.scss';
@import '~base/scss/_vars.scss';

.material-part-list {
	&__header {
		display: grid;
		grid-template-columns: minmax(7em, 3fr) minmax(0, 10fr) minmax(75px, 2fr) 50px minmax(250px, 10fr);
		grid-template-areas: 'position partNumber information price quantity shoppingDetails';
		gap: 1em;
	}

	@include sm {
		grid-row-start: 1;
		grid-column-start: 1;
		background-color: $white;
		z-index: 1;
		padding-left: 65px;
	}
}
