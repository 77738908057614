.sidebar {
	z-index: 1;
	width: max-content;
	position: sticky;
	top: 60px;
}

.curtain-open,
.modal-open {
	.sidebar {
		z-index: 0;
	}
}
