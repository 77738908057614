@import '~base/scss/_vars.scss';

.company-primary-info-edit-modal {
	text-overflow: ellipsis;
	white-space: normal;
	min-height: 530px;

	&__delete-icon {
		cursor: pointer;
	}

	&__file-box {
		border-bottom: 1px solid $very-light-pink-3;
	}

	&__file-size {
		color: $brown-grey;
	}

	&__file-name {
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__form-field {
		padding-left: 0 !important;
	}
}
